import {
  Button,
  IconButton,
  Flex,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ExternalLink, Edit } from 'react-feather';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { SortingRule } from 'react-table';
import RegisterHeader from '../../components/RegisterHeader';
import { IRMARequestDTO } from '../../dtos/IRMARequestDTO';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { exportData } from '../../utils/xlsx';
import { formatDate } from '../../utils/format';
import ModalNewRMARequest from './components/ModalNewRMARequest';
import ModalEditRMARequest from './components/ModalEditRMARequest';
import { ReactTable, TableFooter, SearchInput } from '../../components';
import useReactTableInstance, { Data } from '../../hooks/useReactTableInstance';
import { Container } from './styles';

const SORT_BY_STATE = [{ id: 'name' }];

const MyRMARequests: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [rmaRequests, setRMARequests] = useState<IRMARequestDTO[]>([]);
  const [newRMA, setNewRMA] = useState(false);
  const [selectedRMA, setSelectedRMA] = useState<IRMARequestDTO>();
  const { onOpen, isOpen, onToggle } = useDisclosure();
  const [queryTotalCount, setQueryTotalCount] = useState<number>(0);
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize] = useState(20);
  const [querySortBy, setQuerySortBy] = useState<SortingRule<Data>[]>(
    SORT_BY_STATE,
  );
  const queryTotalPageCount = Math.ceil(queryTotalCount / queryPageSize);
  const [searchString, setSearchString] = useState('');

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      status: yup.string().required('Campo obrigatório'),
      requester_name: yup.string().required('Campo obrigatório'),
      contact: yup.string().required('Campo obrigatório'),
      description: yup.string().required('Campo obrigatório'),
      treatment: yup.string(),
      order_number: yup.string().nullable(),
      new_order: yup.string(),
      order_product: yup
        .string()
        .required('Campo obrigatório')
        .matches(/[0-9]{10}|[0-9]{11}/, 'Número de telefone inválido'),
    });
  }, []);

  const { reset, clearErrors } = useForm<IRMARequestDTO>({
    resolver: yupResolver(validationSchema),
  });

  const formattedData = useMemo(() => {
    const data = rmaRequests.length
      ? rmaRequests.map(rma => ({
          id: rma.id,
          created_at: formatDate(rma.created_at),
          department: rma.department,
          description: rma.description,
          description_short:
            rma.description.length > 20
              ? `${rma.description.substring(0, 20)}...`
              : rma.description,
          order_number: rma.order_number,
          product: rma.product_name,
          tradeName: rma.tradeName,
          user_name: rma.user_name,
          phone: rma.phone,
          email: rma.email,
          status: rma.status,
          treatment: rma.treatment,
          filesURL: rma.filesURL,
        }))
      : [];
    return data;
  }, [rmaRequests]);

  const columns = useMemo(() => {
    return [
      { accessor: 'id', Header: 'Número' },
      { accessor: 'created_at', Header: 'Data' },
      { accessor: 'department', Header: 'Departamento' },
      { accessor: 'description_short', Header: 'Descrição' },
      { accessor: 'order_number', Header: 'Ordem' },
      { accessor: 'product', Header: 'Produto' },
      { accessor: 'tradeName', Header: 'Empresa' },
      { accessor: 'user_name', Header: 'Contato' },
      { accessor: 'phone', Header: 'Telefone' },
      { accessor: 'email', Header: 'Email' },
      { accessor: 'status', Header: 'Status' },
    ];
  }, []);

  const fetchRMARequests = useCallback(() => {
    api
      .get('/rma-requests')
      .then(resp => {
        setRMARequests(resp.data.records);
        setQueryTotalCount(resp.data.length);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error fetching RMA Requests:', error);
      });
  }, []);

  useEffect(() => {
    fetchRMARequests();
  }, [fetchRMARequests]);

  const {
    canNextPage,
    canPreviousPage,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    pageIndex,
    pageOptions,
    prepareRow,
    previousPage,
    sortBy,
  } = useReactTableInstance({
    columns,
    data: formattedData,
    pagination: {
      pageCount: queryTotalPageCount,
      pageIndex: queryPageIndex,
      pageSize: queryPageSize,
    },
    sort: {
      sortBy: querySortBy,
    },
  });

  useEffect(() => {
    setQueryPageIndex(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    setQuerySortBy(sortBy.length ? sortBy : SORT_BY_STATE);
  }, [sortBy]);

  const handleExportData = useCallback(() => {
    const data = rmaRequests.map(rma =>
      [
        rma.id,
        rma.status,
        rma.department,
        rma.description,
        rma.treatment || '',
        rma.new_order_number ?? '',
        rma.created_at.toString(),
        rma.updated_at.toString(),
        rma.user_name,
        rma.phone,
        rma.email,
        rma.product_name,
        rma.order_number,
        rma.tradeName,
        rma.filesURL,
      ].map(value => value.toString() || ''),
    );

    exportData({
      columns: [
        'ID',
        'Status',
        'Department',
        'Description',
        'Treatment',
        'New Order Number',
        'Created At',
        'Updated At',
        'User Name',
        'Phone',
        'Email',
        'Product Name',
        'Order Number',
        'Trade Name',
      ],
      rows: data,
      filename: `relatorio_rma_${format(new Date(), 'yyyy-MM-dd')}`,
    });
  }, [rmaRequests]);

  const toggleModal = useCallback(() => {
    clearErrors();
    reset();
    setModalIsOpen(!modalIsOpen);
    onToggle();
  }, [clearErrors, modalIsOpen, onToggle, reset]);

  const handleNewRMARequest = () => {
    reset();
    setSelectedRMA(undefined);
    toggleModal();
    setNewRMA(true);
    onOpen();
  };

  const handleEdit = useCallback(
    (data: IRMARequestDTO) => {
      setNewRMA(false);
      setSelectedRMA(data);
      toggleModal();
      onOpen();
    },
    [onOpen, toggleModal],
  );

  const onSubmit = useCallback(
    async (data: IRMARequestDTO) => {
      if (!selectedRMA) return;
      try {
        const { treatment, department, status } = data;

        const response = await api.put<IRMARequestDTO>(`/rma-requests`, {
          id: selectedRMA.id,
          treatment,
          department: department.trim(),
          status,
        });

        fetchRMARequests(); // Refresh the list after submission
        if (response.status === 200) {
          window.location.reload();
          setRMARequests(state =>
            state.map(rmaRequest =>
              rmaRequest.id === selectedRMA.id ? response.data : rmaRequest,
            ),
          );

          addToast({
            title: 'RMA atualizado com sucesso',
            type: 'success',
          });
        }
      } catch (error: any) {
        if (error.response && error.response.data) {
          addToast({
            title: 'Erro',
            description: error.response.data.message,
            type: 'error',
          });
        } else {
          addToast({
            title: 'Erro',
            description: error.message,
            type: 'error',
          });
        }
      } finally {
        onToggle();
        fetchRMARequests(); // Ensure fetch after toggling the modal
      }
    },
    [addToast, selectedRMA, onToggle, fetchRMARequests],
  );

  return (
    <>
      {!!newRMA && (
        <ModalNewRMARequest
          isOpen={isOpen}
          onToggle={toggleModal}
          onSubmit={onSubmit} // Use the parent onSubmit function
        />
      )}
      {!!selectedRMA && (
        <ModalEditRMARequest
          isOpen={isOpen}
          onToggle={toggleModal}
          rmaRequest={selectedRMA}
          onSubmit={onSubmit} // Use the parent onSubmit function
        />
      )}
      <Container>
        <Text fontWeight="bold" fontSize="xl" mb="2" mt="2" color="gray.900">
          Pedidos de RMA
        </Text>
        {user.rights === 1 && (
          <Stack flex="1" width="100%">
            <SearchInput
              label="Pesquise por RMA"
              input={{
                placeholder: 'Número do pedido, status e texto da mensagem',
                value: searchString,
                onChange: setSearchString,
              }}
            />
          </Stack>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <div style={{ flex: 1 }}>
            <Button colorScheme="blue" onClick={handleNewRMARequest}>
              Criar RMA
            </Button>
          </div>
          <div style={{ flex: 1 }}>
            <RegisterHeader
              handleExportData={handleExportData}
              downloadDisabled={false}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Flex mb={2}>
              <a
                type="button"
                href={`${process.env.REACT_APP_BASE_URL}/files//rma/declaracao_alutal_rma.docx`}
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '10px 20px',
                  fontSize: '16px',
                  textDecoration: 'none',
                  color: 'inherit',
                  backgroundColor: '#blue',
                  borderRadius: '4px',
                }}
              >
                Modelo Declaração de Descontaminação
              </a>
              <ExternalLink size={24} />
            </Flex>
          </div>
        </div>
        <ReactTable
          getTableBodyProps={getTableProps}
          headerGroups={headerGroups}
          rows={page}
          getTableProps={getTableProps}
          prepareRow={prepareRow}
          rowActions={
            user.rights === 1
              ? [
                  {
                    onClick: (data: any) => {
                      handleEdit(data);
                    },
                    component: (
                      <IconButton aria-label="Editar" icon={<Edit />} />
                    ),
                  },
                ]
              : []
          }
        />
        <TableFooter
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          previousPage={previousPage}
        />
      </Container>
    </>
  );
};

export default MyRMARequests;
