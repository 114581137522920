import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    blue: {
      400: '#00C0FA',
      500: '#0085ae',
      600: '#008FBA',
      700: '#007194',
      800: '#00546E',
      900: '#005e83',
    },
  },
  fonts: {
    body: "'Fira Sans Condensed', sans-serif",
    heading: "'Fira Sans Condensed', sans-serif",
  },
  styles: {
    global: {
      '*, p': {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        outline: 0,
      },
      body: {
        bg: 'gray.50',
        color: 'gray.900',
      },
    },
  },
});
