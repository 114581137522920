import axios, { AxiosInstance } from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
});

const createApi = (apiVersion?: 'v2'): AxiosInstance =>
  axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/api/${
      apiVersion ? `${apiVersion}/` : ''
    }`,
  });

export default api;

export { createApi };
