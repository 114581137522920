import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  margin: 0 auto;

  form {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 360px;

    img {
      height: 50px;
      margin-bottom: 32px;
    }
  }
`;
