import { Stack } from '@chakra-ui/react';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SortingRule } from 'react-table';
import { ReactTable, SearchInput, TableFooter } from '../../../components';
import RegisterHeader from '../../../components/RegisterHeader';

import { IOrderResponseWithPagination } from '../../../dtos/IOrderDTO';
import useReactTableInstance, {
  Data,
} from '../../../hooks/useReactTableInstance';
import api from '../../../services/api';
import { exportData } from '../../../utils/xlsx';

import { Container } from './styles';

interface IOrder {
  id: string;
  customer_id: string;
  date: string;
  order_number: string;
  created_at: string;
  updated_at: string;
  customer: {
    cnpj: string;
    tradeName: string;
  };
}

const SORT_BY_STATE = [{ id: 'date', desc: true }];

const Orders: React.FC = () => {
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [queryTotalCount, setQueryTotalCount] = useState<number>(0);
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize] = useState(20);
  const [querySortBy, setQuerySortBy] = useState<SortingRule<Data>[]>(
    SORT_BY_STATE,
  );
  const queryTotalPageCount = Math.ceil(queryTotalCount / queryPageSize);

  const [searchString, setSearchString] = useState('');

  const formattedOrders = useMemo(() => {
    return orders.map(order => ({
      order_number: order.order_number,
      date: format(new Date(order.date), 'dd-MM-yyyy'),
      tradeName: order.customer.tradeName,
    }));
  }, [orders]);

  const columns = useMemo(() => {
    return [
      {
        accessor: 'order_number',
        Header: 'Número',
      },
      {
        accessor: 'date',
        Header: 'Data',
      },
      {
        accessor: 'tradeName',
        Header: 'Empresa',
      },
    ];
  }, []);

  const {
    canNextPage,
    canPreviousPage,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    pageIndex,
    pageOptions,
    prepareRow,
    previousPage,
    sortBy,
  } = useReactTableInstance({
    columns,
    data: formattedOrders,
    pagination: {
      pageCount: queryTotalPageCount,
      pageIndex: queryPageIndex,
      pageSize: queryPageSize,
    },
    sort: {
      sortBy: querySortBy,
    },
  });

  const handleExportData = useCallback(() => {
    const data = orders.map(order => [
      order.order_number,
      order.date,
      order.customer.tradeName,
    ]);

    exportData({
      columns: ['WO', 'Data', 'Empresa'],
      rows: data,
      filename: `relatorio_pedidos_${format(new Date(), 'yyyy-MM-dd')}`,
    });
  }, [orders]);

  const fetchOrders = useCallback(async () => {
    const sort_by = querySortBy[0].id;
    const order_by = querySortBy[0].desc ? 'DESC' : 'ASC';

    const response = await api.get<IOrderResponseWithPagination>('/v2/orders', {
      params: {
        limit: queryPageSize,
        offset: queryPageIndex * queryPageSize,
        sort_by,
        order_by,
        q: searchString,
      },
    });

    const { records, total_results } = response.data;

    setQueryTotalCount(total_results);
    setOrders(records);
  }, [queryPageIndex, queryPageSize, querySortBy, searchString]);

  const debouncedFetch = useMemo(() => debounce(fetchOrders, 1000), [
    fetchOrders,
  ]);

  useEffect(() => {
    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [debouncedFetch]);

  useEffect(() => {
    setQueryPageIndex(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    setQuerySortBy(sortBy.length ? sortBy : SORT_BY_STATE);
  }, [sortBy]);

  return (
    <Container>
      <RegisterHeader
        downloadDisabled={!orders.length}
        handleExportData={handleExportData}
      />
      <Stack flex="1" width="100%">
        <SearchInput
          label="Pesquise por WO ou Empresa"
          input={{
            placeholder: 'Número do pedido ou Nome da empresa',
            value: searchString,
            onChange: setSearchString,
          }}
        />
      </Stack>
      <hr />
      <ReactTable
        getTableBodyProps={getTableProps}
        headerGroups={headerGroups}
        rows={page}
        getTableProps={getTableProps}
        prepareRow={prepareRow}
      />
      <TableFooter
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        previousPage={previousPage}
      />
    </Container>
  );
};

export default Orders;
