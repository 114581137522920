import React, { useCallback, useState } from 'react';
import { useDropzone, Accept } from 'react-dropzone';

import { Container } from './styles';

interface DropzoneProps {
  acceptFiles: Accept;
  onChange(files: File[]): void;
  maxSize?: number;
  maxFiles?: number;
}

const Dropzone: React.FC<DropzoneProps> = ({
  acceptFiles,
  onChange,
  maxSize = 20 * 1024 * 1024,
  maxFiles = 100, // Default max of 100 files
}) => {
  const [innerText] = useState(
    'Arraste arquivos aqui, ou clique para adicionar',
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onChange(acceptedFiles);
    },
    [onChange],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: acceptFiles,
    maxSize,
    maxFiles,
  });

  return (
    <Container
      isDragActive={isDragActive}
      isDragAccept={isDragAccept}
      isDragReject={isDragReject}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive && isDragReject ? (
          <p> Arquivo não suportado </p>
        ) : isDragAccept ? (
          <p>Solte os arquivos aqui</p>
        ) : (
          <p>{innerText}</p>
        )}
      </div>
    </Container>
  );
};

export default Dropzone;
