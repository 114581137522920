import React, { useMemo } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import Route from '../../routes/Route';
import Products from './Products';
import Orders from './Orders';
import Customers from './Customers';
import Users from './Users';
import QRCodes from './QRCodes';
import { useAuth } from '../../hooks/auth';
import NavHeader from '../../components/NavHeader';

const Register: React.FC = () => {
  const routeMatch = useRouteMatch();
  const { user } = useAuth();

  const links = useMemo(() => {
    return {
      1: [
        {
          name: 'Pedidos',
          to: `${routeMatch.path}/orders`,
        },
        {
          name: 'Produtos',
          to: `${routeMatch.path}/products`,
        },
        {
          name: 'Clientes',
          to: `${routeMatch.path}/customers`,
        },
        {
          name: 'Usuários',
          to: `${routeMatch.path}/users`,
        },
        {
          name: 'QR Codes',
          to: `${routeMatch.path}/qr_codes`,
        },
      ],
      2: [],
      3: [],
    };
  }, [routeMatch]);

  return (
    <div>
      <NavHeader links={links[user.rights || 3]} isSubNavigation />
      <Switch>
        <Redirect
          to={
            user.rights === 1
              ? `${routeMatch.path}/orders`
              : `${routeMatch.path}/my-orders`
          }
          from={`${routeMatch.path}/`}
          exact
        />
        <Route
          component={Orders}
          isPrivate
          exact
          path={`${routeMatch.path}/orders`}
        />
        <Route
          component={Products}
          isPrivate
          path={`${routeMatch.path}/products`}
        />
        <Route
          component={Customers}
          isPrivate
          path={`${routeMatch.path}/customers`}
        />
        <Route component={Users} isPrivate path={`${routeMatch.path}/users`} />
        <Route
          component={QRCodes}
          isPrivate
          path={`${routeMatch.path}/qr_codes`}
        />
      </Switch>
    </div>
  );
};

export default Register;
