import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppProvider from './hooks';
import Routes from './routes';
import Header from './components/Header';

const App: React.FC = () => (
  <BrowserRouter>
    <AppProvider>
      <Header />
      <Routes />
    </AppProvider>
  </BrowserRouter>
);

export default App;
