import { Stack } from '@chakra-ui/react';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SortingRule } from 'react-table';
import { ReactTable, SearchInput, TableFooter } from '../../../components';
import RegisterHeader from '../../../components/RegisterHeader';
import { IResponseWithPagination } from '../../../dtos/IPaginationDTO';
import useReactTableInstance, {
  Data,
} from '../../../hooks/useReactTableInstance';
import api from '../../../services/api';
import { exportData } from '../../../utils/xlsx';

import { Container } from './styles';

interface ICustomer {
  id: string;
  internalCode: string;
  legalName: string;
  tradeName: string;
  cnpj: string;
  ie: string;
}

const SORT_BY_STATE = [{ id: 'tradeName' }];

const Customers: React.FC = () => {
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [queryTotalCount, setQueryTotalCount] = useState<number>(0);
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize] = useState(20);
  const [querySortBy, setQuerySortBy] = useState<SortingRule<Data>[]>(
    SORT_BY_STATE,
  );

  const queryTotalPageCount = Math.ceil(queryTotalCount / queryPageSize);

  const [searchString, setSearchString] = useState('');

  const formattedCustomers = useMemo(() => {
    return customers.length
      ? customers.map(({ internalCode, legalName, tradeName, cnpj, ie }) => ({
          internalCode,
          legalName,
          tradeName,
          cnpj:
            cnpj &&
            cnpj.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
              '$1.$2.$3/$4-$5',
            ),
          ie,
        }))
      : [];
  }, [customers]);

  const columns = useMemo(() => {
    return [
      {
        accessor: 'internalCode',
        Header: 'Código',
      },
      {
        accessor: 'legalName',
        Header: 'Razão Social',
      },
      {
        accessor: 'tradeName',
        Header: 'Nome Fantasia',
      },
      {
        accessor: 'cnpj',
        Header: 'CNPJ',
      },
      {
        accessor: 'ie',
        Header: 'Inscrição Estadual',
      },
    ];
  }, []);

  const {
    canNextPage,
    canPreviousPage,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    pageIndex,
    pageOptions,
    prepareRow,
    previousPage,
    sortBy,
  } = useReactTableInstance({
    columns,
    data: formattedCustomers,
    pagination: {
      pageCount: queryTotalPageCount,
      pageIndex: queryPageIndex,
      pageSize: queryPageSize,
    },
    sort: {
      sortBy: querySortBy,
    },
  });

  const handleExportData = useCallback(() => {
    const data = customers.map(customer => [
      customer.internalCode,
      customer.legalName,
      customer.tradeName,
      customer.cnpj,
      customer.ie,
    ]);

    exportData({
      columns: [
        'Código',
        'Razão Social',
        'Nome comercial',
        'CNPJ',
        'Inscrição Estadual',
      ],
      rows: data,
      filename: `relatorio_clientes_${format(new Date(), 'yyyy-MM-dd')}`,
    });
  }, [customers]);

  useEffect(() => {
    setQueryPageIndex(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    setQuerySortBy(sortBy.length ? sortBy : SORT_BY_STATE);
  }, [sortBy]);

  const fetchCustomers = useCallback(() => {
    const sort_by = querySortBy[0].id;
    const order_by = querySortBy[0].desc ? 'DESC' : 'ASC';

    api
      .get<IResponseWithPagination<ICustomer>>('/customers', {
        params: {
          limit: queryPageSize,
          offset: queryPageIndex * queryPageSize,
          sort_by,
          order_by,
          q: searchString,
        },
      })
      .then(response => {
        const { records, total_results } = response.data;

        setQueryTotalCount(total_results);
        setCustomers(records);
      });
  }, [queryPageIndex, queryPageSize, querySortBy, searchString]);

  const debouncedFetch = useMemo(() => debounce(fetchCustomers, 1000), [
    fetchCustomers,
  ]);

  useEffect(() => {
    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [debouncedFetch]);

  return (
    <Container>
      <RegisterHeader
        downloadDisabled={!customers.length}
        handleExportData={handleExportData}
      />
      <Stack flex="1" width="100%">
        <SearchInput
          label="Filtre os Clientes"
          input={{
            placeholder: 'Código, Razão Social, Nome Fantasia, CNPJ e IE',
            value: searchString,
            onChange: setSearchString,
          }}
        />
      </Stack>
      <hr />
      <ReactTable
        getTableBodyProps={getTableProps}
        headerGroups={headerGroups}
        rows={page}
        getTableProps={getTableProps}
        prepareRow={prepareRow}
      />

      <TableFooter
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        previousPage={previousPage}
      />
    </Container>
  );
};

export default Customers;
