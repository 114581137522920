import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { theme } from '../styles/theme';

const AppProvider: React.FC = ({ children }) => (
  <ChakraProvider theme={theme}>
    <ToastProvider>
      <AuthProvider>{children}</AuthProvider>
    </ToastProvider>
  </ChakraProvider>
);

export default AppProvider;
