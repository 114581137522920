import styled, { css } from 'styled-components';

export const Container = styled.ul`
  margin: 20px 0;

  li {
  }
`;

interface ItemProps {
  isSelectable?: boolean;
}

export const Item = styled.li<ItemProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #444;
  cursor: default;
  text-align: left;
  border-radius: 8px;
  padding: 8px;
  background: #88888811;
  transition: background-color 0.2s;

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      font-size: 12px;
      color: #999;
      margin-top: 5px;
      text-align: left;
    }
  }

  & + li {
    margin-top: 8px;
  }

  svg {
    display: none;
    cursor: pointer;
  }

  &:hover {
    background: #77777722;

    svg {
      display: block;
    }
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  text-decoration: none;
  color: #444;

  button {
    border: 0;
    background: transparent;
    color: #e83f5b;
    cursor: pointer;
  }

  ${props =>
    props.isSelectable &&
    css`
      cursor: pointer;
    `}
`;
