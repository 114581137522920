import React from 'react';
import {
  Button as ChakraUIButton,
  ButtonProps as ChakraUIButtonProps,
} from '@chakra-ui/react';

interface ButtonProps extends ChakraUIButtonProps {
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({ children, loading, ...rest }) => (
  <ChakraUIButton
    size="lg"
    width="100%"
    type="button"
    colorScheme="blue"
    {...rest}
  >
    {loading ? 'Carregando...' : children}
  </ChakraUIButton>
);

export default Button;
