import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  margin: 0 auto;

  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 24px;

    width: 360px;

    img {
      height: 50px;
      margin-bottom: 32px;
    }
  }
`;

export const SignUpButton = styled(Link).attrs({ to: 'sign-up' })`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 16px;
  }

  p {
    font-weight: 500;
  }
`;
