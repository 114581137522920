import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  margin: 0 auto;

  form {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 360px;

    h1 {
      font-size: 24px;
      margin-bottom: 24px;
    }

    img {
      height: 50px;
      margin-bottom: 32px;
    }
  }
`;
