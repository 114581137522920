import React from 'react';
import { Phone, Book } from 'react-feather';
import { useAuth } from '../../hooks/auth';
import logoImg from '../../assets/images/logo.png';
import {
  Container,
  DivCenter,
  DivRight,
  DivRightItens,
  PhoneDiv,
  ManualDiv,
} from './styles';

const Header: React.FC = () => {
  const { user } = useAuth();

  return (
    <>
      {user ? (
        <Container>
          <DivCenter>
            <img src={logoImg} width="200px" alt="Logo Alutal" />
          </DivCenter>
          <DivRight>
            <DivRightItens>
              <PhoneDiv>
                <Phone />
                15 3033-8008
              </PhoneDiv>
              <ManualDiv>
                <Book />
                <a
                  href={`${process.env.REACT_APP_BASE_URL}/files/manual/onecenter.pdf`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Manual
                </a>
              </ManualDiv>
            </DivRightItens>
          </DivRight>
        </Container>
      ) : null}
    </>
  );
};

export default Header;
