import React, { useCallback, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useHistory, useLocation } from 'react-router';
import {
  Box,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Input,
} from '@chakra-ui/react';
import { Lock } from 'react-feather';
import Button from '../../components/Button';

// import logoImg from '../../assets/images/logo.png';

import { Container, Content } from './styles';
import ErrorMessage from '../../components/ErrorMessage';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

const ResetPassword: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      password: yup.string().required('Senha obrigatória'),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref('password')], 'Confirmação incorreta'),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<ResetPasswordFormData> = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        const { password_confirmation, password } = data;

        const token = location.search.replace('?token=', '');

        if (!token) {
          throw new Error();
        }

        await api.post('/password/reset', {
          password_confirmation,
          password,
          token,
        });

        history.push('/');

        addToast({
          type: 'success',
          title: 'Recuperação de senha',
          description:
            'Senha resetada com sucesso, você já pode fazer o login no app',
        });
      } catch (error) {
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.message);
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao tentar recuperar senha',
            description:
              'Ocorreu um erro ao tentar realizar a recuperação de senha, tente novamente',
          });
        }
      }
    },
    [addToast, history, location.search],
  );

  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h1>Resetar senha</h1>
          <FormControl>
            <FormLabel>Nova senha</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Lock color="#0085ae" />}
              />
              <Input name="password" type="password" ref={register} />
            </InputGroup>
            <Box mt={2}>
              <ErrorMessage message={errors.password?.message} />
            </Box>
          </FormControl>

          <FormControl>
            <FormLabel>Confirmar nova senha</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Lock color="#0085ae" />}
              />
              <Input
                name="password_confirmation"
                type="password"
                ref={register}
              />
            </InputGroup>
            <Box mt={2}>
              <ErrorMessage message={errors.password_confirmation?.message} />
            </Box>
          </FormControl>

          <ErrorMessage message={errorMessage} />
          <Button type="submit">Alterar senha</Button>
        </Form>
      </Content>
    </Container>
  );
};

export default ResetPassword;
