/* eslint-disable react/no-children-prop */
import {
  Box,
  Container,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import React, { useCallback, useMemo, useState } from 'react';
import { User, Phone, Mail } from 'react-feather';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router';
import Button from '../../../components/Button';
import { IFormattedOrderProductDTO } from '../../../dtos/IFormattedOrderProductDTO';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import ErrorMessage from '../../../components/ErrorMessage';
// import { Container } from './styles';

interface SupportProps {
  orderProduct: IFormattedOrderProductDTO;
}

interface ISupportFormDate {
  name: string;
  phone: string;
  email: string;
  description: string;
}

const Support: React.FC<SupportProps> = ({ orderProduct }) => {
  const { addToast } = useToast();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      name: yup.string().required('Campo obrigatório'),
      email: yup.string().email().required('Campo obrigatório'),
      phone: yup
        .string()
        .required('Campo obrigatório')
        .matches(/[0-9]{11}/, 'Numero de telefone inválido'),
      description: yup.string().required('Campo obrigatório'),
    });
  }, []);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<ISupportFormDate>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<ISupportFormDate> = useCallback(
    async (data: ISupportFormDate) => {
      setIsLoading(true);

      try {
        const contact = {
          name: data.name,
          phone: data.phone,
          email: data.email,
          description: data.description,
        };

        const product = {
          id: orderProduct.id,
          customer: orderProduct.companyName,
          cnpj: orderProduct.companyCNPJ,
          product: orderProduct.productName,
          internal_code: orderProduct.productCode,
          date: orderProduct.buyDate,
          order_number: orderProduct.orderNumber,
          line_num: orderProduct.lineNum,
        };

        await api.post('/mails/suport', {
          contact,
          product,
        });

        addToast({
          title: 'Sucesso',
          description:
            'A equipe de suporte técnico recebeu o seu chamado, ' +
            'entraremos em contato o quanto antes!',
        });

        history.push(`/orders-products/${id}/details`);
      } catch (error: any) {
        if (error.response && error.response.data) {
          addToast({
            title: 'Erro',
            description: error.response.data.message,
            type: 'error',
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [orderProduct, addToast, history, id],
  );

  return (
    <Container>
      <Text fontSize="xl" fontWeight="600" color="blue.500">
        {orderProduct.productName}
      </Text>
      <Text color="gray.500" mb={4}>
        Confirme o responsável pela solicitação
      </Text>
      <Stack as="form" spacing={4} onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <FormLabel>Nome</FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<User color="#0085ae" />}
            />
            <Input name="name" isInvalid={!!errors.name} ref={register} />
          </InputGroup>
          <Box mt={2}>
            <ErrorMessage message={errors.name?.message} />
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel>Telefone</FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<Phone color="#0085ae" />}
            />
            <Input
              name="phone"
              ref={register}
              type="tel"
              isInvalid={!!errors.phone}
            />
          </InputGroup>
          <Box mt={2}>
            <ErrorMessage message={errors.phone?.message} />
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel>E-email</FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<Mail color="#0085ae" />}
            />
            <Input
              name="email"
              isInvalid={!!errors.email}
              ref={register}
              type="email"
            />
          </InputGroup>
          <Box mt={2}>
            <ErrorMessage message={errors.email?.message} />
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel>Descrição</FormLabel>
          <Textarea
            name="description"
            isInvalid={!!errors.description}
            ref={register}
          />
          <Box mt={2}>
            <ErrorMessage message={errors.description?.message} />
          </Box>
        </FormControl>
        <Button type="submit" isLoading={isLoading || isSubmitting}>
          Solicitar
        </Button>
      </Stack>
    </Container>
  );
};

export default Support;
