import React from 'react';

import { Flex, Tooltip, IconButton, Text } from '@chakra-ui/react';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { TableInstance } from 'react-table';

type TableInstanceProps = Pick<
  TableInstance,
  | 'gotoPage'
  | 'canNextPage'
  | 'canPreviousPage'
  | 'previousPage'
  | 'nextPage'
  | 'pageOptions'
  | 'pageCount'
>;

interface TableFooterProps extends TableInstanceProps {
  pageIndex: number;
  // pageCount: number;
  // pageSize: number;
}

const TableFooter: React.FC<TableFooterProps> = ({
  gotoPage,
  canNextPage,
  canPreviousPage,
  previousPage,
  nextPage,
  pageOptions,
  pageIndex,
  pageCount,
}) => {
  return (
    <Flex justifyContent="space-between" m={4} alignItems="center">
      <Flex>
        <Tooltip label="First Page">
          <IconButton
            onClick={() => gotoPage(0)}
            isDisabled={!canPreviousPage}
            icon={<ArrowLeftIcon h={3} w={3} />}
            mr={4}
            aria-label="first-page-button"
          />
        </Tooltip>
        <Tooltip label="Previous Page">
          <IconButton
            onClick={previousPage}
            isDisabled={!canPreviousPage}
            icon={<ChevronLeftIcon h={6} w={6} />}
            aria-label="previous-page-button"
          />
        </Tooltip>
      </Flex>

      <Flex alignItems="center">
        <Text flexShrink={0} mx={8}>
          {`Page `}
          <Text fontWeight="bold" as="span">
            {pageIndex + 1}
          </Text>
          {` of `}
          <Text fontWeight="bold" as="span">
            {pageOptions.length || 1}
          </Text>
        </Text>
      </Flex>

      <Flex>
        <Tooltip label="Next Page">
          <IconButton
            onClick={nextPage}
            isDisabled={!canNextPage}
            icon={<ChevronRightIcon h={6} w={6} />}
            aria-label="next-page-button"
          />
        </Tooltip>
        <Tooltip label="Last Page">
          <IconButton
            onClick={() => gotoPage(pageCount - 1)}
            isDisabled={!canNextPage}
            icon={<ArrowRightIcon h={3} w={3} />}
            ml={4}
            aria-label="last-page-button"
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default TableFooter;
