/* eslint-disable react/require-default-props */
import React from 'react';

import { Stack, Text, Input } from '@chakra-ui/react';

type SearchInputProp = {
  label: string;
  onSubmit?: () => void;
  input: {
    placeholder: string;
    value: string;
    onChange: (text: string) => void;
  };
};

const SearchInput = ({
  label,
  onSubmit = () => null,
  input,
}: SearchInputProp): React.ReactElement => {
  return (
    <Stack
      as="form"
      spacing={4}
      direction={['column']}
      py={4}
      onSubmit={onSubmit}
      width="100%"
    >
      <Text fontSize="lg">{label}</Text>
      <Input
        placeholder={input.placeholder}
        value={input.value}
        onChange={(event: any) => input.onChange(event.target.value)}
      />
    </Stack>
  );
};

export { SearchInput };
