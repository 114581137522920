import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1200px;
  margin: 0 auto;
`;

export const FilterContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;

  select {
    padding: 8px;
    border-radius: 4px;
    background-color: #fff;
  }

  p {
    margin-bottom: 8px;
  }
`;
