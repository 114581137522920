import {
  useTable,
  usePagination,
  Column,
  SortingRule,
  useSortBy,
} from 'react-table';

export type Data = {
  [key: string]: string | any;
};

interface Pagination {
  pageIndex: number;
  pageCount: number;
  pageSize: number;
}

interface Props {
  columns: Column<Data>[];
  data: Data[];
  onClickRow?(data: any): any;
  rowActions?: Array<{
    onClick(data: any): any;
    component: any;
  }>;
  pagination?: Pagination;
  sort?: {
    sortBy: SortingRule<Data>[];
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useReactTableInstance = ({ columns, data, pagination, sort }: Props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions, // get the length of the object that exist total on the page
    pageCount, // used for counting how many page left
    gotoPage, // Used to indicate which page number we want specific to go at
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy },
  } = useTable<Data>(
    {
      columns,
      data,
      initialState: {
        sortBy: sort?.sortBy,
        pageIndex: pagination?.pageIndex,
        pageSize: pagination?.pageSize,
      },
      manualPagination: true,
      manualSortBy: true,
      pageCount: pagination?.pageCount,
    },
    useSortBy,
    usePagination,
  );

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageIndex,
    pageSize,
    sortBy,
  };
};

export default useReactTableInstance;
