import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import Dropzone from '../../../../components/Dropzone';
import FileList from '../../../../components/FileList';
import ErrorMessage from '../../../../components/ErrorMessage';
import { IRMARequestDTO } from '../../../../dtos/IRMARequestDTO';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';

interface Product {
  id: string;
  name: string;
  serial?: string;
}

interface ModalNewRMARequestProps {
  isOpen: boolean;
  onToggle(): void;
  rmaNewRequest?: Product;
  onSubmit(data: IRMARequestDTO): void;
}

const ModalNewRMARequest: React.FC<ModalNewRMARequestProps> = ({
  isOpen,
  onToggle,
  rmaNewRequest,
}) => {
  const { addToast } = useToast();
  const validationSchema = useMemo(() => {
    return yup.object().shape({});
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = useForm<IRMARequestDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: rmaNewRequest?.id,
      description: rmaNewRequest?.name,
    },
  });

  const user = localStorage.getItem('@AlutalApp:user');
  const userObject = user ? JSON.parse(user) : '';
  const { customer, name } = userObject;
  const [product, setProduct] = useState<Product[]>([
    {
      id: '',
      name: '-',
    },
  ]);
  const [tradeName, setTradeName] = useState(customer.tradeName);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const today = format(new Date(), 'dd/MM/yyyy');

  const handleOrderNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const uppercasedValue = event.target.value.toUpperCase();
    setValue('order_number', uppercasedValue);
  };

  const onSubmit = async (data: IRMARequestDTO) => {
    const formData = new FormData();

    formData.append('order_product_id', selectedProduct); // Ensure this is correct
    formData.append('department', data.department);
    formData.append('description', data.description);

    selectedFiles.forEach(file => {
      formData.append('selectedFiles', file);
    });

    try {
      const response = await api.post('/rma-requests', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        window.location.reload();
        addToast({
          title: 'Sucesso',
          description:
            'RMA criado com sucesso, email enviado com maiores detalhes',
          type: 'success',
        });
      }

      onToggle();
    } catch (error: any) {
      if (error.response && error.response.data) {
        addToast({
          title: 'Error',
          description: error.response.data,
          type: 'error',
        });
      }
    }
  };

  const handleOnBlur = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const woTyped = event.target.value.trim();
    if (woTyped.length === 7 && woTyped.startsWith('WO')) {
      const response = await api.get(`/rma-requests/wo`, {
        params: {
          wo: woTyped,
          customer_id: userObject.customer_id,
        },
      });

      if (Array.isArray(response.data) && response.data[0].length > 0) {
        setTradeName(response?.data?.[0]?.[0]?.tradeName);

        const productFormatted: Product[] = response.data[0].map(
          (option: Product) => ({
            id: option.id + (option.serial ? `--${option.serial}` : ''),
            name: option.name + (option.serial ? `- ${option.serial}` : ''),
          }),
        );
        setProduct(productFormatted);
        setSelectedProduct(response.data[0][0].id);
      } else {
        setProduct([]);
        setSelectedProduct('');
      }
    }
  };

  const handleDepartmentBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.trim();
    setValue('department', trimmedValue);
  };

  const handleChangeSelectedFilterType = useCallback(async event => {
    setSelectedProduct(event.target.value.split('--')[0]);
  }, []);

  const handleDescriptionBlur = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const trimmedValue = event.target.value.trim();
    setValue('description', trimmedValue);
  };

  const handleChangeSelectedFiles = useCallback((newFiles: File[]) => {
    setSelectedFiles(currentSelectedFiles => [
      ...currentSelectedFiles,
      ...newFiles,
    ]);
  }, []);

  const handleRemoveSelectedFile = useCallback((index: number) => {
    setSelectedFiles(currentSelecteFiles =>
      currentSelecteFiles.filter((_, i) => i !== index),
    );
  }, []);

  const handleCloseModal = () => {
    setSelectedFiles([]); // Clear selected files
    onToggle(); // Toggle the modal
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Nova RMA</ModalHeader>
        <ModalHeader>{tradeName}</ModalHeader>
        <Text ml="6" color="gray.900">
          {name}
        </Text>
        <ModalCloseButton />
        <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Data</FormLabel>
              <InputGroup>
                <Input
                  name="id"
                  value={today}
                  isInvalid={!!errors.id}
                  ref={register}
                  disabled
                />
              </InputGroup>
              <Box mt={2}>
                <ErrorMessage message={errors.id?.message} />
              </Box>
            </FormControl>
            <FormControl>
              <FormLabel>Departamento</FormLabel>
              <InputGroup>
                <Input
                  name="department"
                  isInvalid={!!errors.id}
                  ref={register}
                  onBlur={handleDepartmentBlur}
                />
              </InputGroup>
              <Box mt={2}>
                <ErrorMessage message={errors.id?.message} />
              </Box>
            </FormControl>
            <FormControl>
              <FormLabel>
                Número do WO - encontra-se na etiqueta do equipamento
              </FormLabel>
              <InputGroup>
                <Input
                  name="order_number"
                  isInvalid={!!errors.id}
                  ref={register}
                  maxLength={8}
                  onBlur={handleOnBlur}
                  onChange={handleOrderNumberChange}
                />
              </InputGroup>
              <Box mt={2}>
                <ErrorMessage message={errors.id?.message} />
              </Box>
            </FormControl>
            <FormControl>
              <FormLabel>Produto</FormLabel>
              <Select
                name="product_name"
                value={selectedProduct}
                onChange={handleChangeSelectedFilterType}
                minW="250px"
              >
                {product.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <Box mt={2}>
                <ErrorMessage message={errors.id?.message} />
              </Box>
            </FormControl>
            <FormControl>
              <FormLabel>Descrição</FormLabel>
              <InputGroup>
                <Input
                  name="description"
                  isInvalid={!!errors.id}
                  ref={register}
                  onBlur={handleDescriptionBlur}
                />
              </InputGroup>
              <Box mt={2}>
                <ErrorMessage message={errors.id?.message} />
              </Box>
            </FormControl>
            <div>
              <Dropzone
                acceptFiles={{
                  'image/*': ['.png', '.jpg', '.jpeg'],
                  'application/pdf': ['.pdf'],
                }}
                onChange={handleChangeSelectedFiles}
                maxSize={20 * 1024 * 1024}
                maxFiles={5}
              />
              <FileList
                files={selectedFiles}
                onDelete={handleRemoveSelectedFile}
              />
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={isSubmitting}
              disabled={
                !selectedProduct ||
                !selectedProduct.length ||
                !watch('description') ||
                !watch('department')
              }
              colorScheme="blue"
              mr={3}
              type="submit"
            >
              Salvar
            </Button>
            <Button onClick={onToggle}>Cancelar</Button>
          </ModalFooter>
        </Stack>
      </ModalContent>
    </Modal>
  );
};

export default ModalNewRMARequest;
