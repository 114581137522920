/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
import React from 'react';

import { TableInstance } from 'react-table';
import {
  Box,
  Table as ChakraUITable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Stack,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useAuth } from '../../hooks/auth';

type TableInstanceProps = Pick<
  TableInstance,
  'getTableProps' | 'headerGroups' | 'getTableBodyProps' | 'prepareRow' | 'rows'
>;

interface TableProps extends TableInstanceProps {
  onClickRow?(data: any): any;
  rowActions?: Array<{
    onClick(data: any): any;
    component: any;
  }>;
}

const Table: React.FC<TableProps> = ({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  prepareRow,
  rows,
  onClickRow,
  rowActions,
}) => {
  const { user } = useAuth();

  return (
    <Box maxWidth="100%" display={'block'} overflow="auto">
      <ChakraUITable {...getTableProps()}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <Flex as="span" align="center" justify="space-between">
                    {column.render('Header')}
                    <Box boxSize={2}>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ChevronDownIcon w={6} h={6} />
                        ) : (
                          <ChevronUpIcon w={6} h={6} />
                        )
                      ) : (
                        ''
                      )}
                    </Box>
                  </Flex>
                </Th>
              ))}
              {!!rowActions && user.rights === 1 && <Th>Ações</Th>}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                cursor={onClickRow ? 'pointer' : 'default'}
                _hover={
                  onClickRow && {
                    bgColor: 'gray.100',
                  }
                }
                onClick={() => !!onClickRow && onClickRow(row)}
              >
                {row.cells.map(cell => {
                  return (
                    <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                  );
                })}
                {!!rowActions && (
                  <Td>
                    <Stack as="span" direction="row" spacing={2}>
                      {rowActions.map(({ component: C, onClick }, index) => {
                        return React.cloneElement(C as React.ReactElement, {
                          onClick: () => onClick(row.original),
                          key: index,
                        });
                      })}
                    </Stack>
                  </Td>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </ChakraUITable>
    </Box>
  );
};

export default Table;
