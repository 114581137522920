import React, { useMemo, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Briefcase, File, MessageSquare } from 'react-feather';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorMessage from '../../../../components/ErrorMessage';
import { IRMARequestDTO } from '../../../../dtos/IRMARequestDTO';
import { FileLink } from '../../styles';

interface ModalEditRMARequestProps {
  isOpen: boolean;
  onToggle(): void;
  rmaRequest?: IRMARequestDTO;
  onSubmit(data: IRMARequestDTO): void;
}

const ModalEditRMARequest: React.FC<ModalEditRMARequestProps> = ({
  isOpen,
  onToggle,
  rmaRequest,
  onSubmit,
}) => {
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      department: yup.string().required('Campo obrigatório'),
      status: yup.string().required('Campo obrigatório'),
    });
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IRMARequestDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      description: rmaRequest?.description,
      user_name: rmaRequest?.user_name,
      department: rmaRequest?.department,
      tradeName: rmaRequest?.tradeName,
      status: rmaRequest?.status,
      treatment: rmaRequest?.treatment,
      filesURL: rmaRequest?.filesURL || [], // Fallback to an empty array
    },
  });

  useEffect(() => {
    if (rmaRequest) {
      setValue('description', rmaRequest.description);
      setValue('user_name', rmaRequest.user_name);
      setValue('department', rmaRequest.department);
      setValue('tradeName', rmaRequest.tradeName);
      setValue('status', rmaRequest.status);
      setValue('treatment', rmaRequest.treatment);
    }
  }, [rmaRequest, setValue]);

  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Editar RMA</ModalHeader>
        <Stack spacing={2} pl={8} py={4} fontSize="lg" fontWeight="600">
          <Text>{rmaRequest?.tradeName}</Text>
          <Text>{rmaRequest?.user_name}</Text>
          <Text>{rmaRequest?.phone}</Text>
          <Text>{rmaRequest?.email}</Text>
          <Text>{rmaRequest?.product_name}</Text>
        </Stack>
        <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
          <ModalBody pb={6}>
            <FormControl isInvalid={!!errors.department}>
              <FormLabel>Departamento</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Briefcase color="#0085ae" />}
                />
                <Input name="department" ref={register} />
              </InputGroup>
              <Box mt={2}>
                <ErrorMessage message={errors.department?.message} />
              </Box>
            </FormControl>
            <FormControl isInvalid={!!errors.description}>
              <FormLabel>Descrição</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Briefcase color="#0085ae" />}
                />
                <Input name="description" ref={register} disabled />
              </InputGroup>
              <Box mt={2}>
                <ErrorMessage message={errors.description?.message} />
              </Box>
            </FormControl>
            <FormControl isInvalid={!!errors.treatment}>
              <FormLabel>Tratativa</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<MessageSquare color="#0085ae" />}
                />
                <Input name="treatment" ref={register} />
              </InputGroup>
              <Box mt={2}>
                <ErrorMessage message={errors.treatment?.message} />
              </Box>
            </FormControl>
            <Text style={{ marginTop: '14px' }}>Arquivos Anexados:</Text>
            <FormControl>
              {rmaRequest?.filesURL.map((file, index) => (
                <Flex key={index} style={{ margin: '8px 0px' }}>
                  <a
                    href={file.url}
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '5px 5px',
                      fontSize: '14px',
                      textDecoration: 'none',
                      color: 'inherit',
                      borderRadius: '4px',
                      marginBottom: '4px',
                    }}
                  >
                    <File size={24} color="#0085ae" />
                    <FileLink>{file.name}</FileLink>
                  </a>
                </Flex>
              ))}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={isSubmitting}
              colorScheme="blue"
              mr={3}
              type="submit"
            >
              Salvar
            </Button>
            <Button onClick={onToggle}>Cancelar</Button>
          </ModalFooter>
        </Stack>
      </ModalContent>
    </Modal>
  );
};

export default ModalEditRMARequest;
