import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Stack } from '@chakra-ui/react';
import debounce from 'lodash.debounce';

import { SortingRule } from 'react-table';
import RegisterHeader from '../../../components/RegisterHeader';
import api from '../../../services/api';
import { exportData } from '../../../utils/xlsx';
import { IResponseWithPagination } from '../../../dtos/IPaginationDTO';
import { ReactTable, SearchInput, TableFooter } from '../../../components';

import { Container } from './styles';
import useReactTableInstance, {
  Data,
} from '../../../hooks/useReactTableInstance';

interface IProduct {
  internalCode: string;
  name: string;
}

const SORT_BY_STATE = [{ id: 'internalCode' }];

const Products: React.FC = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [queryTotalCount, setQueryTotalCount] = useState<number>(0);
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize] = useState(20);
  const [querySortBy, setQuerySortBy] = useState<SortingRule<Data>[]>(
    SORT_BY_STATE,
  );

  const queryTotalPageCount = Math.ceil(queryTotalCount / queryPageSize);

  const [searchString, setSearchString] = useState('');

  const formattedProducts = useMemo(() => {
    return products.length
      ? products.map(({ internalCode, name }) => ({
          internalCode,
          name,
        }))
      : [];
  }, [products]);

  const columns = useMemo(() => {
    return [
      {
        accessor: 'internalCode',
        Header: 'Código',
      },
      {
        accessor: 'name',
        Header: 'Nome',
      },
    ];
  }, []);

  const {
    canNextPage,
    canPreviousPage,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    pageIndex,
    pageOptions,
    prepareRow,
    previousPage,
    sortBy,
  } = useReactTableInstance({
    columns,
    data: formattedProducts,
    pagination: {
      pageCount: queryTotalPageCount,
      pageIndex: queryPageIndex,
      pageSize: queryPageSize,
    },
    sort: {
      sortBy: querySortBy,
    },
  });

  const fetchProducts = useCallback(() => {
    const sort_by = querySortBy[0].id;
    const order_by = querySortBy[0].desc ? 'DESC' : 'ASC';

    api
      .get<IResponseWithPagination<IProduct>>('/v2/products', {
        params: {
          limit: queryPageSize,
          offset: queryPageIndex * queryPageSize,
          sort_by,
          order_by,
          q: searchString,
        },
      })
      .then(response => {
        const { records, total_results } = response.data;

        setQueryTotalCount(total_results);

        setProducts(records);
      });
  }, [queryPageIndex, queryPageSize, querySortBy, searchString]);

  const debouncedFetch = useMemo(() => debounce(fetchProducts, 1000), [
    fetchProducts,
  ]);

  useEffect(() => {
    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [debouncedFetch]);

  const handleExportData = useCallback(() => {
    const data = products.map(product => [product.internalCode, product.name]);

    exportData({
      columns: ['Código', 'Nome'],
      rows: data,
      filename: `relatorio_produtos_${format(new Date(), 'yyyy-MM-dd')}`,
    });
  }, [products]);

  useEffect(() => {
    setQueryPageIndex(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    setQuerySortBy(sortBy.length ? sortBy : SORT_BY_STATE);
  }, [sortBy]);

  return (
    <Container>
      <RegisterHeader
        downloadDisabled={!products.length}
        handleExportData={handleExportData}
      />
      <Stack flex="1" width="100%">
        <SearchInput
          label="Pesquise por Código ou Nome"
          input={{
            placeholder: 'Código ou Nome',
            value: searchString,
            onChange: setSearchString,
          }}
        />
      </Stack>
      <hr />
      <ReactTable
        getTableBodyProps={getTableProps}
        headerGroups={headerGroups}
        rows={page}
        getTableProps={getTableProps}
        prepareRow={prepareRow}
      />

      <TableFooter
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        previousPage={previousPage}
      />
    </Container>
  );
};

export default Products;
