import React, { useCallback, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { Mail, User, Briefcase, Lock, Phone } from 'react-feather';
import Button from '../../components/Button';

import logoImg from '../../assets/images/logo.png';
import { useAuth } from '../../hooks/auth';

import { Container, Content } from './styles';
import ErrorMessage from '../../components/ErrorMessage';
import api from '../../services/api';

interface SignUpFormData {
  name: string;
  email: string;
  company: string;
  phone: string;
  password: string;
  password_confirmation: string;
}

const SignUp: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      name: yup.string().required('Campo obrigatório'),
      company: yup.string().required('Campo obrigatório'),
      email: yup.string().email().required('Campo obrigatório'),
      phone: yup
        .string()
        .required('Campo obrigatório')
        .matches(/[0-9]{11}/, 'Numero de telefone inválido'),
      password: yup
        .string()
        .required('Campo obrigatório')
        .min(4, 'Mínimo 4 caracteres'),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], 'As senhas precisam ser iguais'),
    });
  }, []);

  const { register, handleSubmit, errors } = useForm<SignUpFormData>({
    resolver: yupResolver(validationSchema),
  });

  const { signIn } = useAuth();

  const onSubmit: SubmitHandler<SignUpFormData> = useCallback(
    async (data: SignUpFormData) => {
      setLoading(true);

      try {
        await api.post('/users/', {
          name: data.name,
          email: data.email,
          phone: data.phone,
          company: data.company,
          password: data.password,
        });

        signIn({
          email: data.email,
          password: data.password,
        });
      } catch (error) {
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.message);
          setTimeout(() => setErrorMessage(''), 4000);
        }
      } finally {
        setLoading(false);
      }
    },
    [signIn],
  );

  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <img src={logoImg} alt="Logo Alutal" />

          <FormControl>
            <FormLabel>Nome completo</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<User color="#0085ae" />}
              />
              <Input name="name" type="text" ref={register} />
            </InputGroup>
            <Box mt={2}>
              <ErrorMessage message={errors.name?.message} />
            </Box>
          </FormControl>

          <FormControl>
            <FormLabel>E-mail</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Mail color="#0085ae" />}
              />
              <Input name="email" type="email" ref={register} />
            </InputGroup>
            <Box mt={2}>
              <ErrorMessage message={errors.email?.message} />
            </Box>
          </FormControl>

          <FormControl>
            <FormLabel>Empresa</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Briefcase color="#0085ae" />}
              />
              <Input name="company" type="text" ref={register} />
            </InputGroup>
            <Box mt={2}>
              <ErrorMessage message={errors.company?.message} />
            </Box>
          </FormControl>

          <FormControl>
            <FormLabel>Telefone</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Phone color="#0085ae" />}
              />
              <Input name="phone" type="text" ref={register} />
            </InputGroup>
            <Box mt={2}>
              <ErrorMessage message={errors.phone?.message} />
            </Box>
          </FormControl>

          <FormControl>
            <FormLabel>Senha</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Lock color="#0085ae" />}
              />
              <Input name="password" type="password" ref={register} />
            </InputGroup>
            <Box mt={2}>
              <ErrorMessage message={errors.password?.message} />
            </Box>
          </FormControl>

          <FormControl>
            <FormLabel>Confirmação da Senha</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Lock color="#0085ae" />}
              />
              <Input
                name="password_confirmation"
                type="password"
                ref={register}
              />
            </InputGroup>
            <Box mt={2}>
              <ErrorMessage message={errors.password_confirmation?.message} />
            </Box>
          </FormControl>

          <ErrorMessage message={errorMessage} />
          <Button loading={loading} type="submit">
            Cadastrar
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default SignUp;
