import { Stack, Text, Input } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ExternalLink, Trash } from 'react-feather';
import { SortingRule } from 'react-table';
import { ReactTable, TableFooter } from '../../components';

import AlertMessage from '../../components/AlertMessage';
import Button from '../../components/Button';
import Dropzone from '../../components/Dropzone';
import FileList from '../../components/FileList';
import Modal from '../../components/Modal';
import { useToast } from '../../hooks/toast';
import useReactTableInstance, { Data } from '../../hooks/useReactTableInstance';
import api from '../../services/api';
import { formatDate } from '../../utils/format';

import { Container, ActionsContainer } from './styles';

const columns = [
  { Header: 'Código', accessor: 'code' },
  { Header: 'Nome', accessor: 'name' },
  { Header: 'Data', accessor: 'created_at' },
  { Header: 'Responsável', accessor: 'user' },
  { Header: 'Ações', accessor: 'actions', disableSortBy: true },
];

interface ICertificate {
  code: string;
  name: string;
  url: string;
  created_at?: string;
  user_id?: string;
  user?: {
    name: string;
  };
}

interface IHandleDeleteCertificateDTO {
  serial_number: string;
  certificate_name: string;
}

interface IAlertMessage {
  type: 'success' | 'danger' | 'info';
  message: string;
}

const SORT_BY_STATE: SortingRule<Data>[] = [{ id: 'created_at' }];

const Certificates: React.FC = () => {
  const { addToast } = useToast();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [certificateToBeDeleted, setCertificateToBeDeleted] = useState(
    {} as IHandleDeleteCertificateDTO,
  );
  const [queryTotalCount, setQueryTotalCount] = useState<number>(0);
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize] = useState(20);
  const [querySortBy, setQuerySortBy] = useState<SortingRule<Data>[]>(
    SORT_BY_STATE,
  );
  const [queryInputValue, setQueryInputValue] = useState('');

  const queryTotalPageCount = Math.ceil(queryTotalCount / queryPageSize);

  const [certificates, setCertificates] = useState<ICertificate[]>(
    [] as ICertificate[],
  );
  const [alertMessage, setAlertMessage] = useState<IAlertMessage>(
    {} as IAlertMessage,
  );

  const filteredCertificates = useMemo(() => {
    const filtered = certificates.filter(certificate =>
      Object.keys(certificate).some(certificateKey =>
        String(certificate[certificateKey as keyof ICertificate])
          .toLowerCase()
          .includes(String(queryInputValue).toLowerCase()),
      ),
    );
    return filtered;
  }, [certificates, queryInputValue]);

  const [deleteCertificateModalShow, setDeleteCertificateModalShow] = useState(
    false,
  );

  const handleShowDeleteCertificateModal = useCallback(() => {
    setDeleteCertificateModalShow(!deleteCertificateModalShow);
  }, [deleteCertificateModalShow]);

  const sortCertificates = useCallback(
    (certificates: ICertificate[]) => {
      const sortBy = querySortBy[0] as {
        id: keyof ICertificate;
        desc: boolean;
      };

      const newCertificates = [...certificates];

      if (sortBy.desc) {
        return newCertificates.sort((a, b) =>
          String(b[sortBy?.id]).localeCompare(String(a[sortBy?.id])),
        );
      }

      return newCertificates.sort((a, b) =>
        String(a[sortBy?.id]).localeCompare(String(b[sortBy?.id])),
      );
    },
    [querySortBy],
  );

  useEffect(() => {
    api.get<ICertificate[]>('/certificates').then(resp => {
      setCertificates(sortCertificates(resp.data));
      setQueryTotalCount(resp.data.length);
    });
  }, [sortCertificates]);

  const handleChangeSelectedFiles = useCallback((files: File[]) => {
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
  }, []);

  const handleRemoveSelectedFile = useCallback((index: number) => {
    setSelectedFiles(currentSelecteFiles =>
      currentSelecteFiles.filter((_, i) => i !== index),
    );
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const formData = new FormData();

      selectedFiles.forEach(file => {
        formData.append('files', file);
      });

      const resp = await api.post<ICertificate[]>('/certificates', formData);

      addToast({
        title: 'Sucesso!',
        type: 'success',
        description: 'Upload dos certificados feito com sucesso',
      });

      setCertificates(oldState => {
        const newCertificates = resp.data.filter(
          certificate =>
            !oldState.find(
              c =>
                `${c.code}-${c.name}` ===
                `${certificate.code}-${certificate.name}`,
            ),
        );

        return sortCertificates([...oldState, ...newCertificates]);
      });
    } catch (error: any) {
      if (error.response && error.response.data) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: error.response.data.message,
        });
      } else {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Ocorreu um erro inesperado, tenta novamente!',
        });
      }
    } finally {
      setTimeout(() => setAlertMessage({} as IAlertMessage), 3000);
      setSelectedFiles([]);
    }
  }, [selectedFiles, addToast, sortCertificates]);

  const handleDeleteCertificate = useCallback(async () => {
    const { serial_number, certificate_name } = certificateToBeDeleted;

    try {
      await api.delete(`/certificates/${serial_number}/${certificate_name}`);

      addToast({
        title: 'Sucesso',
        description: 'Certificado deletado com sucesso',
        type: 'success',
      });

      setCertificates(oldCertificates =>
        oldCertificates.filter(
          ({ code, name }) =>
            `${code}-${name}` !== `${serial_number}-${certificate_name}`,
        ),
      );
    } catch (error: any) {
      if (error.response && error.response.data) {
        addToast({
          title: 'Erro',
          description: error.response.data.message,
          type: 'error',
        });
      } else {
        addToast({
          title: 'Erro',
          description: error.message,
          type: 'error',
        });
      }
    } finally {
      setCertificateToBeDeleted({} as IHandleDeleteCertificateDTO);
      handleShowDeleteCertificateModal();
    }
  }, [certificateToBeDeleted, addToast, handleShowDeleteCertificateModal]);

  const formattedCertificates = useMemo(() => {
    const limit = queryPageSize;
    const offset = queryPageIndex * queryPageSize;

    const formattedCertificates = queryInputValue
      ? filteredCertificates
      : certificates;

    return formattedCertificates
      .slice(offset, limit + offset)
      .map(({ code, name, created_at, user, url }) => ({
        code,
        name,
        created_at: created_at ? formatDate(created_at) : '',
        user: user?.name ? user?.name : '',
        actions: (
          <ActionsContainer>
            <button type="button">
              <a href={url} rel="noopener noreferrer" target="_blank">
                <ExternalLink size={24} />
              </a>
            </button>
            <button
              type="button"
              onClick={() => {
                setCertificateToBeDeleted({
                  serial_number: code,
                  certificate_name: name,
                });

                handleShowDeleteCertificateModal();
              }}
            >
              <Trash size={24} />
            </button>
          </ActionsContainer>
        ),
      }));
  }, [
    certificates,
    filteredCertificates,
    handleShowDeleteCertificateModal,
    queryInputValue,
    queryPageIndex,
    queryPageSize,
  ]);

  const {
    canNextPage,
    canPreviousPage,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    pageIndex,
    pageOptions,
    prepareRow,
    previousPage,
    sortBy,
  } = useReactTableInstance({
    columns,
    data: formattedCertificates,
    pagination: {
      pageCount: queryTotalPageCount,
      pageIndex: queryPageIndex,
      pageSize: queryPageSize,
    },
    sort: {
      sortBy: querySortBy,
    },
  });

  useEffect(() => {
    setQueryPageIndex(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    setQuerySortBy(sortBy.length ? sortBy : SORT_BY_STATE);
  }, [sortBy]);

  useEffect(() => {
    setCertificates(certificates => sortCertificates(certificates));
  }, [querySortBy, sortCertificates]);

  return (
    <Container>
      <Modal
        show={deleteCertificateModalShow}
        title="Alerta"
        body={<p>Tem certeza que deseja deletar o certificado?</p>}
        onHide={handleShowDeleteCertificateModal}
        buttons={[
          {
            text: 'Confirmar',
            variant: 'primary',
            onClick: handleDeleteCertificate,
          },
          {
            text: 'Cancelar',
            variant: 'secondary',
            onClick: handleShowDeleteCertificateModal,
          },
        ]}
      />
      <div>
        <Dropzone
          acceptFiles={{
            'image/*': ['.png', '.jpg', '.jpeg'],
            'application/pdf': ['.pdf'],
          }}
          onChange={handleChangeSelectedFiles}
        />
        <FileList files={selectedFiles} onDelete={handleRemoveSelectedFile} />
        <AlertMessage message={alertMessage.message} type={alertMessage.type} />
        <Button onClick={handleSubmit} disabled={selectedFiles.length === 0}>
          Adicionar/Enviar Certificados
        </Button>
      </div>
      <div>
        <Stack spacing={4} direction={['column']} py={4}>
          <Text fontSize="lg">Pesquisar certificado por nome ou serial</Text>
          <Input
            placeholder="Busque por Serial ou Nome"
            value={queryInputValue}
            onChange={(event: any) => setQueryInputValue(event.target.value)}
          />
        </Stack>
        <ReactTable
          getTableBodyProps={getTableProps}
          headerGroups={headerGroups}
          rows={page}
          getTableProps={getTableProps}
          prepareRow={prepareRow}
        />
        <TableFooter
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          previousPage={previousPage}
        />
      </div>
    </Container>
  );
};

export default Certificates;
