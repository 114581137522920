import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  Container,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { FileText } from 'react-feather';
import { useCallback } from 'react';
import { IFormattedOrderProductDTO } from '../../../dtos/IFormattedOrderProductDTO';
import api from '../../../services/api';
import ModalOpenRMA from './ModalOpenRMA';
import { useToast } from '../../../hooks/toast';

interface DetailsProps {
  orderProduct: IFormattedOrderProductDTO;
}

interface IOrderProductCertificateDTO {
  name: string;
  url: string;
}

const Details: React.FC<DetailsProps> = ({ orderProduct }) => {
  const history = useHistory();
  const { addToast } = useToast();
  const { id } = useParams<{ id: string }>();
  const [errorMessage, setErrorMessage] = useState('');
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [orderProductCertificates, setOrderProductCertificates] = useState<
    IOrderProductCertificateDTO[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    api
      .get<IOrderProductCertificateDTO[]>(`/order-products/${id}/certificates`)
      .then(resp => {
        setOrderProductCertificates(resp.data);
      })
      .catch(error => {
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            'Ocorreu um erro inesperado, tente novamente mais tarde',
          );
        }
      });
  }, [id]);

  const handleOpenRMA = useCallback(async () => {
    setIsLoading(true);
    try {
      await api.post('/rma-requests', {
        order_product_id: id,
      });

      onClose();

      addToast({
        title: 'Sucesso',
        description: 'Um RMA foi aberto com sucesso!',
        type: 'success',
      });

      history.push('/rma-requests');
    } catch (error: any) {
      if (error.response && error.response.data) {
        addToast({
          title: 'Erro',
          description: error.response.data.message,
          type: 'error',
        });
      } else {
        addToast({
          title: 'Erro',
          description: error.message,
          type: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [id, addToast, onClose, history]);

  return (
    <>
      <ModalOpenRMA
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleOpenRMA}
        isLoading={isLoading}
        productName={orderProduct.productName}
      />
      <Container maxW="container.sm" p={4}>
        {orderProduct.id ? (
          <Flex flexDir="column">
            <Text fontSize="xl" fontWeight="600" color="blue.500" mb={4}>
              {orderProduct.productName}
            </Text>
            <VStack align="flex-start" mb={4}>
              <HStack spacing={4}>
                <Text fontWeight="600">Empresa</Text>
                <Text>{orderProduct.companyName}</Text>
              </HStack>
              <HStack spacing={4}>
                <Text fontWeight="600">CNPJ</Text>
                <Text>{orderProduct.companyCNPJ}</Text>
              </HStack>
              <HStack spacing={4}>
                <Text fontWeight="600">Código Alutal</Text>
                <Text>{orderProduct.productCode}</Text>
              </HStack>
              <HStack spacing={4}>
                <Text fontWeight="600">Data da compra</Text>
                <Text>{orderProduct.buyDate}</Text>
              </HStack>
              <HStack spacing={4}>
                <Text fontWeight="600">Número do pedido</Text>
                <Text>{orderProduct.orderNumber}</Text>
              </HStack>
              <HStack spacing={4}>
                <Text fontWeight="600">Item do pedido</Text>
                <Text>{orderProduct.lineNum}</Text>
              </HStack>
            </VStack>
            <Accordion allowToggle mb={4}>
              {orderProductCertificates.length ? (
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Text fontWeight="600" fontSize="md" color="blue.500">
                        Certificados
                      </Text>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel py={4}>
                    {orderProductCertificates.map(certificate => (
                      <Button
                        key={certificate.url}
                        variant="link"
                        as="a"
                        href={certificate.url}
                        rel="noopener noreferrer"
                        target="_blank"
                        leftIcon={<FileText />}
                      >
                        {certificate.name}
                      </Button>
                    ))}
                  </AccordionPanel>
                </AccordionItem>
              ) : (
                <></>
              )}
              {orderProduct.datasheet !== '' ? (
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Text fontWeight="600" fontSize="md" color="blue.500">
                        Folha de Dados
                      </Text>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel py={4}>
                    <Button
                      key={orderProduct.datasheet}
                      variant="link"
                      as="a"
                      href={orderProduct.datasheet}
                      rel="noopener noreferrer"
                      target="_blank"
                      leftIcon={<FileText />}
                    >
                      Folha de Dados - Download
                    </Button>
                  </AccordionPanel>
                </AccordionItem>
              ) : (
                <></>
              )}
            </Accordion>
            <Stack direction={['row', 'column']}>
              <Button
                colorScheme="blue"
                onClick={() => history.push(`/orders-products/${id}/budget`)}
              >
                Orçamento
              </Button>
              <Button
                colorScheme="blue"
                variant="outline"
                onClick={() => history.push(`/orders-products/${id}/support`)}
              >
                Suporte
              </Button>
              <Button colorScheme="blue" variant="outline" onClick={onOpen}>
                Abrir um RMA
              </Button>
            </Stack>
          </Flex>
        ) : (
          <Text>{errorMessage}</Text>
        )}
      </Container>
    </>
  );
};

export default Details;
