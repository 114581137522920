import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';

// import { Container } from './styles';

interface ModalOpenRMAProps {
  isOpen: boolean;
  onClose(): void;
  onToggle?(): void;
  onConfirm(): void;
  isLoading: boolean;
  productName: string;
}

const ModalOpenRMA: React.FC<ModalOpenRMAProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  productName,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Abrir um RMA</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Seu
            <b> RMA </b>
            (Return Merchandise Authorization ou Autorizaçao de Retorno de
            Mercadoria) será criado!
            <br />
            <br />
            Por favor, envie o equipamento:
            <br />
            <b>{`${productName}`}</b>
            <br />
            ao endereço:
            <br />
            <b>
              Rua Sebastiana Nunes, 133 Jardim Ana Claudia, CEP 18112-575
              Votorantim / SP
            </b>
            <br />
            aos cuidados do
            <b> Departamento de Assistência Técnica.</b>
            <br />
            <br />
            A Nota fiscal deve ser do tipo:
            <br />
            <b>“Remessa para Conserto”, com o CFOP 5915</b>
            <br />
            Deve ser enviado em conjunto com o material na embalagem física, a
            declaração de&nbsp;
            <a
              href={`${process.env.REACT_APP_BASE_URL}/files//rma/declaracao_alutal_rma.docx`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Descontaminação preenchida
            </a>
            .
            <br />
            Essa declaração trata-se de um Documento onde o cliente nos aponta a
            quais produtos químicos o equipamento foi sujeito para que possamos
            tomar os devidos cuidados no procedimento de assistência técnica.
            <br />
            Por favor envie esta declaração também em avanço para o e-mail:
            rma@alutal.com.br
            <br />
            Em caso de duvidas, por favor ligue para – Assistencia Técnica –
            <b> 15 3033-8008 / 15 98800-9417</b>
            <br />
            A Alutal agradece seu contato e permanece à sua disposição.
            <br />
            <br />
            Obrigado!
            <br />
            <br />
            <a
              href={`${process.env.REACT_APP_BASE_URL}/files/rma/declaracao_alutal_rma.docx`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Download - Modelo Declaração de Descontaminação
            </a>
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            isLoading={isLoading}
            onClick={onConfirm}
          >
            Abrir
          </Button>
          <Button variant="ghost" onClick={onClose} isLoading={isLoading}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalOpenRMA;
