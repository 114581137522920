import styled from 'styled-components';

export const Container = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #005e83;

  position: relative;
  height: 70px;
  padding-top: 10px;
`;

export const DivCenter = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width="70px"
`;

export const DivRight = styled.div`
  position: absolute;
  right: 0;
  padding-top: 10px;
`;

export const DivRightItens = styled.div`
  display: flex;
`;

export const PhoneDiv = styled.div`
  display: flex;
`;

export const ManualDiv = styled.div`
  display: flex;
  padding: 0px 35px;
`;
