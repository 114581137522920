import styled, { css } from 'styled-components';

interface IContainerProps {
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
}

const dragColors = {
  accepted: css`
    border: 2px dashed #12a454;

    p {
      color: #12a454;
    }
  `,
  rejected: css`
    border: 2px dashed #e83f5b;

    p {
      color: #e83f5b;
    }
  `,
};

export const Container = styled.div<IContainerProps>`
  > div {
    padding: 32px;
    border: 2px dashed #eee;

    display: flex;
    align-items: center;
    justify-content: center;

    ${props =>
      props.isDragActive && props.isDragReject
        ? dragColors.rejected
        : props.isDragAccept && dragColors.accepted}

  cursor: pointer;
`;
