import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  max-width: 1200px;
  margin: 0 auto;

  > div {
    margin-bottom: 32px;
  }
`;

export const ActionsContainer = styled.span`
  button {
    color: var(--color-text-in-primary);
    background: transparent;
    border: none;
    border-radius: 4px;
    padding: 4px;
  }

  a {
    color: inherit;
  }

  button:hover {
    background-color: var(--color-menu-hover);
    color: #fff;
  }

  button + button {
    margin-left: 8px;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  font-size: 14px;

  thead {
    background-color: #e6e6f0;
  }

  th,
  td {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 2px 4px;
  }

  th {
    text-align: center;
  }

  button {
    color: var(--color-text-in-primary);
    background: transparent;
    border: none;
    border-radius: 4px;
    padding: 4px;
  }

  a {
    color: inherit;
  }

  button:hover {
    background-color: var(--color-menu-hover);
    color: #fff;
  }

  button + button {
    margin-left: 8px;
  }
`;
