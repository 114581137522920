/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
import React from 'react';

import {
  useTable,
  Column,
  useSortBy,
  SortingRule,
  usePagination,
} from 'react-table';
import {
  Box,
  Table as ChakraUITable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Stack,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

type Data = {
  [key: string]: string | any;
};

interface Pagination {
  pageIndex: number;
  pageCount: number;
  pageSize: number;
}

interface Props {
  columns: Column<Data>[];
  data: Data[];
  sortBy?: SortingRule<Data>[];
  onClickRow?(data: any): any;
  rowActions?: Array<{
    onClick(data: any): any;
    component: any;
  }>;
  pagination?: Pagination;
}

const Table: React.FC<Props> = ({
  columns,
  data,
  sortBy,
  onClickRow,
  rowActions,
  pagination = {} as Pagination,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<Data>(
    {
      columns,
      data,
      initialState: {
        sortBy,
        pageIndex: pagination.pageIndex || 0,
        pageSize: pagination.pageSize || 20,
      },
      manualPagination: true,
      pageCount: pagination.pageCount || 1,
    },
    useSortBy,
    usePagination,
  );

  return (
    <Box maxWidth="100%" display={'block'} overflow="auto">
      <ChakraUITable {...getTableProps()}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <Flex as="span" align="center" justify="space-between">
                    {column.render('Header')}
                    <Box boxSize={6}>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ChevronDownIcon w={6} h={6} />
                        ) : (
                          <ChevronUpIcon w={6} h={6} />
                        )
                      ) : (
                        ''
                      )}
                    </Box>
                  </Flex>
                </Th>
              ))}
              {!!rowActions && <Th>Ações</Th>}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                cursor={onClickRow ? 'pointer' : 'default'}
                _hover={
                  onClickRow && {
                    bgColor: 'gray.100',
                  }
                }
                onClick={() => !!onClickRow && onClickRow(row)}
              >
                {row.cells.map(cell => {
                  return (
                    <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                  );
                })}
                {!!rowActions && (
                  <Td>
                    <Stack as="span" direction="row" spacing={2}>
                      {rowActions.map(({ component: C, onClick }, index) => {
                        return React.cloneElement(C as React.ReactElement, {
                          onClick: () => onClick(row.original),
                          key: index,
                        });
                      })}
                    </Stack>
                  </Td>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </ChakraUITable>
    </Box>
  );
};

export default Table;
