import React from 'react';
import Alert from 'react-bootstrap/Alert';

import { Container } from './styles';

interface AlertMessageProps {
  type?: 'success' | 'info' | 'danger';
  message?: string;
}

const AlertMessage: React.FC<AlertMessageProps> = ({ type, message }) => {
  return message ? (
    <Container>
      <Alert color={type || 'info'}>{message}</Alert>
    </Container>
  ) : null;
};

export default AlertMessage;
