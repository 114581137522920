interface IUserRight {
  right: 1 | 2 | 3;
  label: string;
}

export const usersRights: IUserRight[] = [
  {
    right: 1,
    label: 'FUNCIONÁRIO ALUTAL',
  },
  {
    right: 2,
    label: 'QR CODE e PEDIDOS',
  },
  {
    right: 3,
    label: 'QR CODE',
  },
];

export const getRightLabel = (value: 1 | 2 | 3): string | undefined => {
  return usersRights.find(userRight => userRight.right === value)?.label;
};

export const getRightNumber = (label: string): 1 | 2 | 3 | undefined => {
  return usersRights.find(userRight => userRight.label === label)?.right;
};
