/* eslint-disable react/no-children-prop */
import React, { useCallback, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Lock, LogIn, Mail } from 'react-feather';
import {
  Box,
  Button as ChakraUIButton,
  Input,
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';

import logoImg from '../../assets/images/logo.png';
import { useAuth } from '../../hooks/auth';

import { Container, Content } from './styles';
import ErrorMessage from '../../components/ErrorMessage';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      email: yup.string().email().required('Campo obrigatório'),
      password: yup.string().required('Campo obrigatório'),
    });
  }, []);

  const { register, handleSubmit, errors } = useForm<SignInFormData>({
    resolver: yupResolver(validationSchema),
  });
  const { signIn } = useAuth();

  const onSubmit: SubmitHandler<SignInFormData> = useCallback(
    async (data: SignInFormData) => {
      try {
        setIsLoading(true);
        const { email, password } = data;

        await signIn({
          email,
          password,
        });
      } catch (error) {
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [signIn],
  );

  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <img src={logoImg} alt="Logo Alutal" />
          <FormControl>
            <FormLabel>E-mail</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Mail color="#0085ae" />}
              />
              <Input name="email" type="email" ref={register} />
            </InputGroup>
            <Box mt={2}>
              <ErrorMessage message={errors.email?.message} />
            </Box>
          </FormControl>

          <FormControl>
            <FormLabel>Senha</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Lock color="#0085ae" />}
              />
              <Input name="password" type="password" ref={register} />
            </InputGroup>
            <Box mt={2}>
              <ErrorMessage message={errors.password?.message} />
            </Box>
          </FormControl>
          <ErrorMessage message={errorMessage} />
          <Button type="submit" isLoading={isLoading}>
            Entrar
          </Button>
        </Form>
        <Flex align="center" justify="center" direction="column">
          <ChakraUIButton
            variant="link"
            alignSelf="flex-end"
            as={Link}
            to="/forgot-password"
          >
            Esqueceu a senha?
          </ChakraUIButton>
          <ChakraUIButton
            leftIcon={<LogIn />}
            variant="link"
            mt="16"
            colorScheme="blue"
            as={Link}
            to="/sign-up"
          >
            Criar conta
          </ChakraUIButton>
        </Flex>
      </Content>
    </Container>
  );
};

export default SignIn;
