import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Download } from 'react-feather';
import Button from '../Button';

interface RegisterHeaderProps {
  handleExportData(event: any): void;
  downloadDisabled: boolean;
  label?: string;
}

const RegisterHeader: React.FC<RegisterHeaderProps> = ({
  handleExportData,
  downloadDisabled,
  label = 'Exportar para XSLX',
}) => {
  return (
    <Flex mb={2}>
      <Button
        onClick={handleExportData}
        disabled={downloadDisabled}
        leftIcon={<Download />}
      >
        {label}
      </Button>
    </Flex>
  );
};

export default RegisterHeader;
