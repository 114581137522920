import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Text,
  Container,
  Stack,
  Badge,
  AccordionPanel,
  Box,
  Button,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { FileText } from 'react-feather';
import { formatDate, formatMoney } from '../../utils/format';

import api from '../../services/api';
import { IOrderDTO } from '../../dtos/IOrderDTO';

const MyOrders: React.FC = () => {
  const [orders, setOrders] = useState<IOrderDTO[]>([]);

  const formattedOrders = useMemo(() => {
    // TODO: should be a backend function - sort
    return orders.map(order => {
      const ordered_order_status =
        !!order.order_status && !!order.order_status.length
          ? _.sortBy(order.order_status, 'date').filter(
              order_status => !order_status.order_product_id,
            )
          : [];

      const orderStatus = _.maxBy(ordered_order_status, 'date');

      const ordered_order_products =
        !!order.order_products && !!order.order_products.length
          ? _.sortBy(order.order_products, 'line_num').map(order_product => {
              const lastStatus = _.maxBy(order_product.status, 'date');

              return {
                ...order_product,
                lastStatus:
                  lastStatus?.message !== orderStatus?.message &&
                  _.gt(lastStatus?.date, orderStatus?.date)
                    ? lastStatus?.message
                    : '',
              };
            })
          : [];

      return {
        id: order.id,
        order_number: order.order_number,
        date: order.date,
        delivery_date: order.delivery_date,
        tradeName: order.customer.tradeName,
        orderStatus: orderStatus?.message,
        value: order.value,
        order_status: ordered_order_status,
        order_products: ordered_order_products,
        nfe: order.nfe,
      };
    });
  }, [orders]);

  useEffect(() => {
    api.get<IOrderDTO[]>('/orders').then(response => {
      const records = response.data;

      setOrders(records);
    });
  }, []);

  return (
    <Container maxW="container.lg">
      <Accordion allowToggle>
        {formattedOrders.map(order => (
          <AccordionItem key={order.id}>
            <h2>
              <AccordionButton>
                <Stack spacing="4">
                  <Stack direction="row" spacing="8" align="center">
                    <Text fontWeight="bold">{order.order_number}</Text>
                    <Text>{formatMoney(order.value)}</Text>
                    {!!order.orderStatus && (
                      <Badge colorScheme="blue">{order.orderStatus}</Badge>
                    )}
                    {order.nfe.map(value => (
                      <Button
                        key={value}
                        variant="link"
                        as="a"
                        href={value}
                        rel="noopener noreferrer"
                        target="_blank"
                        leftIcon={<FileText />}
                      >
                        NF-e
                      </Button>
                    ))}
                  </Stack>
                  <Stack>
                    <Text textAlign="start">
                      {`Pedido: ${formatDate(order.date)}`}
                    </Text>
                    <Text textAlign="start">
                      {`Entrega prevista: ${formatDate(
                        order.delivery_date,
                      )}\n ...`}
                    </Text>
                  </Stack>
                </Stack>
              </AccordionButton>
            </h2>
            <AccordionPanel>
              {!!order.order_status.length && (
                <Box>
                  <Text fontWeight="600" my={2}>
                    Status do pedido
                  </Text>
                  <Stack spacing={2}>
                    {order.order_status.map(order_status => (
                      <Stack
                        key={order_status.id}
                        align="center"
                        spacing={2}
                        direction="row"
                      >
                        <Text>{formatDate(order_status.date)}</Text>
                        <Badge colorScheme="blue">{order_status.message}</Badge>
                      </Stack>
                    ))}
                  </Stack>
                </Box>
              )}
              {!!order.order_products.length && (
                <Box>
                  <Text fontWeight="600" my={2}>
                    Itens do pedido
                  </Text>
                  <Stack spacing={2} align="flex-start">
                    {!!order.order_products &&
                      !!order.order_products.length &&
                      order.order_products.map(order_product => (
                        <Box key={order_product.id}>
                          <Button
                            variant="link"
                            as={Link}
                            color="gray.600"
                            to={`/orders-products/${order_product.id}/details`}
                          >
                            {order_product.product.name}
                          </Button>
                          {order_product.lastStatus && (
                            <Badge ml={2} colorScheme="blue">
                              {order_product.lastStatus}
                            </Badge>
                          )}
                        </Box>
                      ))}
                  </Stack>
                </Box>
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Container>
  );
};

export default MyOrders;
