import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

import Route from './Route';
import Dashboard from '../pages/Dashboard';
import Register from '../pages/Register';
import Statistics from '../pages/Statistics';
import Certificates from '../pages/Certificates';

import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import { useAuth } from '../hooks/auth';
import MyOrders from '../pages/MyOrders';
import NavHeader, { MenuLink } from '../components/NavHeader';
import MyOrdersProducts from '../pages/MyOrdersProducts';
import MyOrderProduct from '../pages/MyOrderProduct';
import MyRMARequests from '../pages/MyRMARequests';

const menuLinks: {
  [key: number]: Array<MenuLink>;
} = {
  3: [
    {
      name: 'Home',
      to: '/dashboard',
    },
    {
      name: 'RMA',
      to: '/rma-requests',
    },
  ],
  2: [
    {
      name: 'Home',
      to: '/dashboard',
    },
    {
      name: 'Meus Pedidos',
      to: '/my-orders',
    },
    {
      name: 'RMA',
      to: '/rma-requests',
    },
  ],
  1: [
    {
      name: 'Home',
      to: '/dashboard',
    },
    {
      name: 'Cadastros',
      to: '/registers',
      submenu: [
        {
          name: 'Pedidos',
          to: `/registers/orders`,
        },
        {
          name: 'Produtos',
          to: `/registers/products`,
        },
        {
          name: 'Clientes',
          to: `/registers/customers`,
        },
        {
          name: 'Usuários',
          to: `/registers/users`,
        },
        {
          name: 'QR Codes',
          to: `/registers/qr_codes`,
        },
      ],
    },
    {
      name: 'Estatísticas',
      to: '/statistics',
    },
    {
      name: 'Certificados',
      to: '/certificates',
    },
    {
      name: 'RMA',
      to: '/rma-requests',
    },
  ],
};

const Routes: React.FC = () => {
  const { user } = useAuth();

  const links = useMemo(() => {
    return menuLinks[user ? user.rights : 3];
  }, [user]);

  return (
    <Switch>
      <Route path="/sign-in" exact component={SignIn} />
      <Route path="/sign-up" exact component={SignUp} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />

      <Route path="/" isPrivate>
        <NavHeader links={links} />
        <Route path="/dashboard" component={Dashboard} isPrivate />
        <Route path="/registers" component={Register} isPrivate />
        <Route path="/my-orders" component={MyOrders} isPrivate exact />
        <Route
          path="/orders-products"
          component={MyOrdersProducts}
          isPrivate
          exact
        />
        <Route
          path="/orders-products/:id"
          component={MyOrderProduct}
          isPrivate
        />
        <Route path="/statistics" component={Statistics} isPrivate />
        <Route path="/certificates" component={Certificates} isPrivate />
        <Route path="/rma-requests" component={MyRMARequests} isPrivate />
      </Route>
    </Switch>
  );
};
export default Routes;
