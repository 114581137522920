import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
`;

export const FileLink = styled.text`
  margin-left: 5px;
  :hover {
    color: #0085ae;
  }
`;
