import React from 'react';
import Alert from 'react-bootstrap/Alert';

import { Container } from './styles';

interface ErrorMessageProps {
  message?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return message ? (
    <Container>
      <Alert variant="danger">{message}</Alert>
    </Container>
  ) : null;
};

export default ErrorMessage;
