import React, { useCallback, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useHistory } from 'react-router';
import {
  Box,
  FormControl,
  Input,
  FormLabel,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { Mail } from 'react-feather';
import Button from '../../components/Button';

import logoImg from '../../assets/images/logo.png';

import { Container, Content } from './styles';
import ErrorMessage from '../../components/ErrorMessage';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      email: yup.string().email().required('Campo obrigatório'),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<ForgotPasswordFormData> = useCallback(
    async (data: ForgotPasswordFormData) => {
      try {
        setIsLoading(true);
        const { email } = data;

        await api.post('/password/forgot', {
          email,
        });

        history.push('/');

        addToast({
          type: 'success',
          title: 'Recuperação de senha',
          description:
            'Enviamos um e-mail de recuperação de senha para você, cheque sua caixa de entrada!',
        });
      } catch (error) {
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.message);
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao tentar recuperar senha',
            description:
              'Ocorreu um erro ao tentar realizar a recuperação de senha, tente novamente',
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <img src={logoImg} alt="Logo Alutal" />
          <h1>Recupere sua senha</h1>
          <FormControl>
            <FormLabel>E-mail de recuperação</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Mail color="#0085ae" />}
              />
              <Input name="email" type="email" ref={register} />
            </InputGroup>
            <Box mt={2}>
              <ErrorMessage message={errors.email?.message} />
            </Box>
          </FormControl>

          <ErrorMessage message={errorMessage} />
          <Button type="submit" isLoading={isLoading}>
            Recuperar
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
