import { Flex, Spinner } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { IFormattedOrderProductDTO } from '../../dtos/IFormattedOrderProductDTO';
import { IOrderProductDTO } from '../../dtos/IOrderProductDTO';
import Route from '../../routes/Route';
import api from '../../services/api';
import { formatCNPJ, formatDate } from '../../utils/format';
import Budget from './Budget';
import Details from './Details';
import Support from './Support';

interface RouteParams {
  id: string;
}

const MyOrderProduct: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const routeMatch = useRouteMatch();

  const [isLoading, setIsLoading] = useState(true);
  const [orderProduct, setOrderProduct] = useState<IOrderProductDTO>();

  const formattedOrderProduct = useMemo(() => {
    return orderProduct
      ? {
          id: orderProduct.id,
          orderNumber: orderProduct?.order.order_number,
          lineNum: orderProduct?.line_num,
          productName: orderProduct?.product.name,
          productCode: orderProduct?.product.internalCode,
          companyName: orderProduct?.order.customer.tradeName,
          companyCNPJ: formatCNPJ(orderProduct?.order.customer.cnpj),
          buyDate: formatDate(orderProduct?.order.date),
          datasheet: orderProduct.datasheet,
        }
      : ({} as IFormattedOrderProductDTO);
  }, [orderProduct]);

  useEffect(() => {
    api
      .get<IOrderProductDTO>(`/order-products/${id}`)
      .then(resp => {
        setOrderProduct(resp.data);
      })
      .finally(() => setIsLoading(false));
  }, [id]);

  if (isLoading)
    return (
      <Flex maxW="container.lg" h="100vh" align="center" justify="center">
        <Spinner size="xl" />
      </Flex>
    );

  return (
    <div>
      <Switch>
        <Redirect
          to={`${routeMatch.path}/details`}
          from={`${routeMatch.path}/`}
          exact
        />
        <Route isPrivate exact path={`${routeMatch.path}/details`}>
          <Details orderProduct={formattedOrderProduct} />
        </Route>

        <Route isPrivate exact path={`${routeMatch.path}/budget`}>
          <Budget orderProduct={formattedOrderProduct} />
        </Route>

        <Route isPrivate exact path={`${routeMatch.path}/support`}>
          <Support orderProduct={formattedOrderProduct} />
        </Route>
      </Switch>
    </div>
  );
};

export default MyOrderProduct;
