import {
  Button,
  Container,
  Flex,
  Input,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { FormEvent, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import api from '../../services/api';
import { formatCNPJ, formatDate } from '../../utils/format';
import Table from '../../components/Table';

interface ICustomer {
  id: string;
  internalCode: string;
  legalName: string;
  tradeName: string;
  cnpj: string;
  ie: string;
  enable: string;
  created_at: string;
  updated_at: string;
}

interface IOrderProduct {
  id: string;
  line_num: number;
  product_id: string;
  delivery_date: string;
  order_id: string;
  quantity: string;
  created_at: string;
  updated_at: string;
  product: {
    created_at: string;
    enable: string;
    id: string;
    internalCode: string;
    name: string;
    updated_at: string;
  };
  order: {
    id: string;
    customer_id: string;
    value: string;
    date: string;
    delivery_date: string;
    order_number: string;
    created_at: string;
    updated_at: string;
    datasheet: string;
  };
  product_serial: {
    id: string;
    serial: string;
    nfe: string;
    order_id: string;
    order_product_id: string;
    created_at: string;
    updated_at: string;
  };
}

interface IResponse {
  customer: ICustomer;
  order_products: IOrderProduct[];
}

interface TableRow {
  id: string;
  order_number: string;
  delivery_date: string;
  line_num: number;
  product: string;
  internalCode: string;
  customer: string;
  cnpj: string;
  date: string;
}

const MyOrdersProducts: React.FC = () => {
  const history = useHistory();
  const [ordersProducts, setOrdersProducts] = useState<IOrderProduct[]>([]);
  const [customer, setCustomer] = useState<ICustomer>();
  const [cnpjInputValue, setCnpjInputValue] = useState('');
  const [queryInputValue, setQueryInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (event: FormEvent) => {
    event.preventDefault();
    setOrdersProducts([]);
    setErrorMessage('');
    setIsLoading(true);

    try {
      const response = await api.get<IResponse>('/order-products', {
        params: {
          q: queryInputValue,
          cnpj: cnpjInputValue,
        },
      });

      if (!response.data.order_products.length) {
        setErrorMessage('Não encontramos nenhum pedido');
      }

      setOrdersProducts(response.data.order_products);
      setCustomer(response.data.customer);
    } catch (error: any) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(
          'Ocorreu um erro inesperado, tente novamente mais tarde',
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formattedOrdersProducts = useMemo((): TableRow[] => {
    return ordersProducts.length && !!customer
      ? ordersProducts.map(orderProduct => ({
          id: orderProduct.id,
          order_number: orderProduct.order.order_number,
          delivery_date: formatDate(orderProduct.delivery_date),
          line_num: orderProduct.line_num,
          product: orderProduct.product.name,
          internalCode: orderProduct.product.internalCode,
          customer: customer.tradeName,
          cnpj: formatCNPJ(customer.cnpj || ''),
          date: formatDate(orderProduct.order.date),
          datasheet: orderProduct.order.datasheet,
        }))
      : [];
  }, [ordersProducts, customer]);

  const columns = useMemo(() => {
    return [
      {
        accessor: 'order_number',
        Header: 'Número do pedido',
      },
      {
        accessor: 'product',
        Header: 'Produto',
      },
      {
        accessor: 'date',
        Header: 'Data da compra',
      },
      {
        accessor: 'line_num',
        Header: 'Número do pedido',
      },
      {
        accessor: 'customer',
        Header: 'Empresa',
      },
      {
        accessor: 'cnpj',
        Header: 'CNPJ',
      },

      {
        accessor: 'internalCode',
        Header: 'Código do produto',
      },
    ];
  }, []);

  const handleClickTableRow = (data: { original: TableRow }) => {
    history.push(`/orders-products/${data.original.id}/details`);
  };

  return (
    <Container maxW="container.lg">
      <Flex direction="column" pt={4}>
        <Text fontSize="lg">Pesquisar pedido</Text>
        <Stack
          as="form"
          spacing={4}
          direction={['column', 'row']}
          py={4}
          onSubmit={handleSearch}
        >
          <Input
            placeholder="CNPJ"
            type="number"
            isRequired
            value={cnpjInputValue}
            onChange={event => setCnpjInputValue(event.target.value)}
          />
          <Input
            placeholder="Serial ou NFe"
            type="number"
            isRequired
            value={queryInputValue}
            onChange={event => setQueryInputValue(event.target.value)}
          />
          <Button
            colorScheme="blue"
            minW="150px"
            type="submit"
            isLoading={isLoading}
          >
            Pesquisar
          </Button>
        </Stack>
      </Flex>
      {isLoading ? (
        <Flex align="center" justify="center" height="50vh">
          <Spinner />
        </Flex>
      ) : !!ordersProducts.length && !!customer ? (
        <>
          <Text my={6} fontSize="2xl">
            Pedidos encontrados
          </Text>
          <Table
            columns={columns}
            data={formattedOrdersProducts}
            sortBy={[{ id: 'date' }]}
            onClickRow={handleClickTableRow}
          />
        </>
      ) : (
        <Flex align="center" justify="center">
          <Text color="gray.600">{errorMessage}</Text>
        </Flex>
      )}
    </Container>
  );
};

export default MyOrdersProducts;
