import React, { useCallback, useEffect, useState } from 'react';
import BootstrapToast from 'react-bootstrap/Toast';
import { AlertCircle, CheckCircle, Info } from 'react-feather';

import { Container } from './styles';

import { IToastMessage, useToast } from '../../../hooks/toast';

interface IProps {
  message: IToastMessage;
}

const icons = {
  info: <Info size={20} />,
  success: <CheckCircle size={20} />,
  error: <AlertCircle size={20} />,
};

const Toast: React.FC<IProps> = ({ message }) => {
  const [show, setShow] = useState(false);
  const { removeToast } = useToast();

  useEffect(() => {
    setShow(true);
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3500);

    return () => {
      clearTimeout(timer);
    };
  }, [message.id, removeToast]);

  const handleOnClose = useCallback(() => {
    setShow(false);
    removeToast(message.id);
  }, [removeToast, message]);

  return (
    <Container
      key={message.id}
      onClose={handleOnClose}
      show={show}
      delay={5000}
      autohide
      type={message.type}
    >
      <BootstrapToast.Header>
        {icons[message.type || 'info']}
        <strong className="mr-auto">{message.title}</strong>
      </BootstrapToast.Header>
      {!!message.description && (
        <BootstrapToast.Body>
          <p>{message.description}</p>
        </BootstrapToast.Body>
      )}
    </Container>
  );
};

export default Toast;
