/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Link as ChakraUILink,
  Stack,
  useColorModeValue,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
} from '@chakra-ui/react';

import React from 'react';
import { NavLink as ReactRouterDomNavLink, useHistory } from 'react-router-dom';
import { LogOut } from 'react-feather';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useAuth } from '../../hooks/auth';

interface BaseMenu {
  name: string;
  to: string;
}

export interface MenuLink extends BaseMenu {
  submenu?: BaseMenu[];
}

const NavLink = ({
  link,
  isSubNavigation,
}: {
  link: MenuLink;
  isSubNavigation: boolean;
}) => (
  <ChakraUILink
    as={ReactRouterDomNavLink}
    px={2}
    py={1}
    rounded="md"
    color={isSubNavigation ? 'gray.700' : 'white'}
    _hover={{
      textDecoration: 'none',
      bg: !isSubNavigation && 'blue.600',
      color: useColorModeValue('blue.800', 'blue.400'),
    }}
    _activeLink={{
      textDecoration: 'none',
      color: isSubNavigation && 'blue.500',
      fontWeight: '600',
    }}
    to={link.to}
  >
    {link.name}
  </ChakraUILink>
);

const MobileNavHeader = ({
  links,
  onPress,
}: {
  links: MenuLink[];
  onPress: (to: string) => void;
}) => {
  return (
    <Box
      bg="blue.900"
      py="2"
      display={['block', 'block', 'none']}
      justifyContent="space-between"
    >
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon fontSize="2xl" />}
        />
        <MenuList>
          {links.map((link, index) =>
            link.submenu ? (
              <>
                <MenuDivider key={index} />
                <MenuGroup title={link.name}>
                  {link.submenu.map(sublink => (
                    <MenuItem
                      key={sublink.name}
                      onClick={() => onPress(sublink.to)}
                    >
                      {sublink.name}
                    </MenuItem>
                  ))}
                </MenuGroup>
                <MenuDivider />
              </>
            ) : (
              <MenuItem key={link.name} onClick={() => onPress(link.to)}>
                {link.name}
              </MenuItem>
            ),
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};

const NavHeader: React.FC<{ isSubNavigation?: boolean; links: MenuLink[] }> = ({
  isSubNavigation = false,
  links,
}) => {
  const { signOut, user } = useAuth();
  const history = useHistory();

  return (
    <Box bg={isSubNavigation ? 'gray.100' : 'blue.900'} px={4}>
      <Flex
        h={isSubNavigation ? 10 : 16}
        alignItems="center"
        justifyContent="space-between"
      >
        {!isSubNavigation && (
          <MobileNavHeader links={links} onPress={to => history.push(to)} />
        )}
        <HStack
          spacing={8}
          alignItems="center"
          display={['none', 'none', 'block']}
        >
          <HStack as="nav" spacing={4} display={{ base: 'none', md: 'flex' }}>
            {links.map(link => (
              <NavLink
                key={link.name}
                link={link}
                isSubNavigation={isSubNavigation}
              />
            ))}
          </HStack>
        </HStack>
        {!isSubNavigation && (
          <Stack
            flex={{ base: 1, md: 0 }}
            justify="flex-end"
            direction="row"
            spacing={10}
          >
            {user.customer && (
              <Text
                color="gray.100"
                width="220px"
                paddingTop="8px"
                display={['none', 'none', 'block']}
                textAlign="right"
              >
                {user.customer.tradeName}
              </Text>
            )}
            <Text
              color="gray.100"
              width="150px"
              paddingTop="8px"
              display={['none', 'none', 'block']}
            >
              {user.name}
            </Text>
            <IconButton
              aria-label="Logout Button"
              bg="blue.700"
              _hover={{
                bg: 'blue.600',
              }}
              color="gray.100"
              icon={<LogOut />}
              onClick={signOut}
            />
          </Stack>
        )}
      </Flex>
    </Box>
  );
};

export default NavHeader;
