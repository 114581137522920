export const formatDate = (date: string): string => {
  const dt = new Date(date);

  function padToTwo(number: number) {
    return number > 9 ? number : `0${number}`;
  }

  const year = dt.getUTCFullYear();
  const month = dt.getUTCMonth() + 1;
  const day = dt.getUTCDate();

  return `${padToTwo(day)}/${padToTwo(month)}/${year}`;
};

export const formatCNPJ = (cnpj: string): string => {
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

export const formatMoney = (value: string | number): string => {
  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(Number(value));
};
