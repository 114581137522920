import React from 'react';
import BModal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

// import { Container } from './styles';

interface ModalProps {
  show: boolean;
  onHide(): void;
  title: string;
  body: React.ReactNode;
  buttons: Array<{
    text: string;
    variant: 'primary' | 'secondary';
    onClick(): void;
  }>;
}

const Modal: React.FC<ModalProps> = ({
  show,
  onHide,
  title,
  body,
  buttons,
}) => {
  return (
    <BModal show={show} onHide={onHide}>
      <BModal.Header closeButton>{title}</BModal.Header>
      <BModal.Body>{body}</BModal.Body>
      <BModal.Footer>
        {buttons.map((button, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Button key={index} variant={button.variant} onClick={button.onClick}>
            {button.text}
          </Button>
        ))}
      </BModal.Footer>
    </BModal>
  );
};

export default Modal;
