import xlsx from 'xlsx';

interface HandleExportDTO {
  columns: Array<string>;
  rows: Array<Array<string>>;
  filename?: string;
}

const exportData = ({ columns, rows, filename }: HandleExportDTO): void => {
  const ws = xlsx.utils.aoa_to_sheet<string>([columns, ...rows]);
  const wb = xlsx.utils.book_new();

  xlsx.utils.book_append_sheet(wb, ws, 'Sheet');

  xlsx.writeFile(wb, filename ? `${filename}.xlsx` : 'export_file.xlsx');
};

export { exportData };
