/* eslint-disable react/no-children-prop */
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
  Stack,
  Select,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Briefcase, Edit, Mail, Phone, Trash, User } from 'react-feather';
import debounce from 'lodash.debounce';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { SortingRule } from 'react-table';
import ErrorMessage from '../../../components/ErrorMessage';
import RegisterHeader from '../../../components/RegisterHeader';
import { ICustomerDTO } from '../../../dtos/ICustomerDTO';
import { IUserDTO } from '../../../dtos/IUserDTO';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import {
  getRightNumber,
  getRightLabel,
  usersRights,
} from '../../../utils/userRights';
import { exportData } from '../../../utils/xlsx';
import { IResponseWithPagination } from '../../../dtos/IPaginationDTO';

import { ReactTable, TableFooter, SearchInput } from '../../../components';
import useReactTableInstance, {
  Data,
} from '../../../hooks/useReactTableInstance';

import { Container } from './styles';

const SORT_BY_STATE = [{ id: 'name' }];

type CustomerResumedState = Pick<
  ICustomerDTO,
  'id' | 'tradeName' | 'internalCode' | 'cnpj'
>;

const Users: React.FC = () => {
  const { addToast } = useToast();
  const [users, setUsers] = useState<IUserDTO[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUserDTO>();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [customers, setCustomers] = useState<CustomerResumedState[]>([]);
  const [
    selectedCustomer,
    setSelectedCustomer,
  ] = useState<CustomerResumedState>();
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [queryTotalCount, setQueryTotalCount] = useState<number>(0);
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize] = useState(20);
  const [querySortBy, setQuerySortBy] = useState<SortingRule<Data>[]>(
    SORT_BY_STATE,
  );

  const queryTotalPageCount = Math.ceil(queryTotalCount / queryPageSize);

  const [searchString, setSearchString] = useState('');

  const toggleDeleteDialog = () => {
    setDeleteDialogIsOpen(!deleteDialogIsOpen);
  };

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      name: yup.string().required('Campo obrigatório'),
      email: yup.string().email().required('Campo obrigatório'),
      company: yup.string(),
      customer_id: yup.string().nullable(),
      rights: yup.string(),
      phone: yup
        .string()
        .required('Campo obrigatório')
        .matches(/[0-9]{10}|[0-9]{11}/, 'Numero de telefone inválido'),
    });
  }, []);

  const {
    register,
    handleSubmit,
    errors,
    reset,
    clearErrors,
    setValue,
    formState: { isSubmitting },
  } = useForm<IUserDTO>({
    resolver: yupResolver(validationSchema),
  });

  const formattedUsers = useMemo(() => {
    return users.map(
      ({ id, name, email, phone, company, rights, customer_id }) => ({
        id,
        name,
        email,
        phone,
        company,
        customer_id,
        rights: getRightLabel(rights),
      }),
    );
  }, [users]);

  useEffect(() => {
    if (selectedCustomer) {
      setValue('customer_id', selectedCustomer.id);
      setValue('company', selectedCustomer.tradeName);
    }
  }, [selectedCustomer, setValue]);

  const columns = useMemo(() => {
    return [
      {
        accessor: 'name',
        Header: 'Nome',
      },
      {
        accessor: 'email',
        Header: 'E-mail',
      },
      {
        accessor: 'phone',
        Header: 'Telefone',
      },
      {
        accessor: 'company',
        Header: 'Empresa',
      },
      {
        accessor: 'rights',
        Header: 'Permissões',
      },
    ];
  }, []);

  const fetchUsers = useCallback(() => {
    const sort_by = querySortBy[0].id;
    const order_by = querySortBy[0].desc ? 'DESC' : 'ASC';

    api
      .get<IResponseWithPagination<IUserDTO>>('/users', {
        params: {
          limit: queryPageSize,
          offset: queryPageIndex * queryPageSize,
          sort_by,
          order_by,
          q: searchString,
        },
      })
      .then(response => {
        const { records, total_results } = response.data;

        setQueryTotalCount(total_results);
        setUsers(records);
      });
  }, [queryPageIndex, queryPageSize, querySortBy, searchString]);

  const debouncedFetch = useMemo(() => debounce(fetchUsers, 1000), [
    fetchUsers,
  ]);

  useEffect(() => {
    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [debouncedFetch]);
  useEffect(() => {
    api
      .get<Array<CustomerResumedState>>('/customers/resumes')
      .then(response => {
        setCustomers(response.data);
      });
  }, []);

  const {
    canNextPage,
    canPreviousPage,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    pageIndex,
    pageOptions,
    prepareRow,
    previousPage,
    sortBy,
  } = useReactTableInstance({
    columns,
    data: formattedUsers,
    pagination: {
      pageCount: queryTotalPageCount,
      pageIndex: queryPageIndex,
      pageSize: queryPageSize,
    },
    sort: {
      sortBy: querySortBy,
    },
  });

  useEffect(() => {
    setQueryPageIndex(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    setQuerySortBy(sortBy.length ? sortBy : SORT_BY_STATE);
  }, [sortBy]);

  const handleExportData = useCallback(() => {
    const data = users.map(customer => [
      customer.name,
      customer.email,
      customer.phone,
      customer.company,
    ]);

    exportData({
      columns: ['Nome', 'E-mail', 'Telefone', 'Empresa'],
      rows: data,
      filename: `relatorio_clientes_${format(new Date(), 'yyyy-MM-dd')}`,
    });
  }, [users]);

  const toggleModal = () => {
    clearErrors();
    reset();
    setModalIsOpen(!modalIsOpen);
  };

  const handleEdit = (data: IUserDTO) => {
    // reset();
    toggleModal();
    const formattedUser = {
      ...data,
      rights: getRightNumber(String(data.rights)) || 3,
    };

    setSelectedUser(formattedUser);

    reset(formattedUser);
  };

  const handleClickButtonDelete = (data: IUserDTO) => {
    setSelectedUser(data);
    toggleDeleteDialog();
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      if (selectedUser) {
        await api.delete(`/users/${selectedUser.id}`);

        const newUsersArray = users.filter(user => user.id !== selectedUser.id);

        toggleDeleteDialog();
        setUsers(newUsersArray);
        setSelectedUser(undefined);

        addToast({
          title: 'Sucesso',
          description: 'Usuário deletado com sucesso',
          type: 'success',
        });
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        addToast({
          title: 'Error',
          description: error.response.data,
          type: 'error',
        });
      } else {
        addToast({
          title: 'Error',
          description: 'Ocorreu um erro inesperado, tente novamente mais tarde',
          type: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data: IUserDTO) => {
    try {
      const editedUser = selectedUser;

      Object.assign(editedUser, {
        ...data,
      });

      const response = await api.put('/users', {
        id: editedUser?.id,
        name: editedUser?.name,
        company: editedUser?.company,
        customer_id: editedUser?.customer_id,
        email: editedUser?.email,
        phone: editedUser?.phone,
        rights: editedUser?.rights,
      });

      const newUsersArray = users.filter(user => user.id !== editedUser?.id);

      newUsersArray.push(response.data);

      setUsers(newUsersArray);

      toggleModal();
    } catch (error: any) {
      if (error.response && error.response.data) {
        addToast({
          title: 'Error',
          description: error.response.data,
          type: 'error',
        });
      }
      addToast({
        title: 'Error',
        description: 'Ocorreu um erro inesperado, tente novamente mais tarde',
        type: 'error',
      });
    }
  };

  return (
    <Container>
      <AlertDialog
        isOpen={deleteDialogIsOpen}
        leastDestructiveRef={cancelRef}
        onClose={toggleDeleteDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deletar usuário
            </AlertDialogHeader>

            <AlertDialogBody>
              Você deseja deletar esse usuário? Essa ação não poderá ser
              revertida depois.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={toggleDeleteDialog}>
                Cancelar
              </Button>
              <Button
                isLoading={isLoading}
                colorScheme="red"
                onClick={handleDelete}
                ml={3}
              >
                Deletar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal isOpen={modalIsOpen} onClose={toggleModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar usuário</ModalHeader>
          <ModalCloseButton />
          <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Nome</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<User color="#0085ae" />}
                  />
                  <Input name="name" isInvalid={!!errors.name} ref={register} />
                </InputGroup>
                <Box mt={2}>
                  <ErrorMessage message={errors.name?.message} />
                </Box>
              </FormControl>
              <FormControl>
                <FormLabel>E-mail</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Mail color="#0085ae" />}
                  />
                  <Input
                    name="email"
                    ref={register}
                    isInvalid={!!errors.email}
                  />
                </InputGroup>
                <Box mt={2}>
                  <ErrorMessage message={errors.email?.message} />
                </Box>
              </FormControl>
              <FormControl>
                <FormLabel>Telefone</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Phone color="#0085ae" />}
                  />
                  <Input
                    name="phone"
                    ref={register}
                    type="tel"
                    isInvalid={!!errors.phone}
                  />
                </InputGroup>
                <Box mt={2}>
                  <ErrorMessage message={errors.phone?.message} />
                </Box>
              </FormControl>

              <FormControl>
                <FormLabel>Nome da Empresa</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Briefcase color="#0085ae" />}
                  />
                  <Input
                    name="company"
                    ref={register}
                    type="tel"
                    isInvalid={!!errors.company}
                  />
                </InputGroup>
                <Box mt={2}>
                  <ErrorMessage message={errors.company?.message} />
                </Box>
              </FormControl>
              <FormControl>
                <FormLabel>Empresa</FormLabel>
                <Select
                  name="customer_id"
                  {...register('customer_id')}
                  onChange={(event: any) => {
                    const findCustomer = customers.find(
                      customer => customer.id === event.target.value,
                    );

                    if (findCustomer) {
                      setSelectedCustomer(findCustomer);
                    }
                  }}
                  defaultValue={
                    selectedUser?.customer_id ? selectedUser?.customer_id : 0
                  }
                >
                  <option value={0} disabled>
                    Selecione uma empresa
                  </option>
                  {!!customers.length &&
                    customers.map(customer => (
                      <option key={customer.id} value={customer.id}>
                        {customer.tradeName.concat(
                          ' - ',
                          customer.internalCode,
                          ' - ',
                          customer.cnpj,
                        )}
                      </option>
                    ))}
                </Select>
                <Box mt={2}>
                  <ErrorMessage message={errors.customer_id?.message} />
                </Box>
              </FormControl>
              <FormControl>
                <FormLabel>Permissões</FormLabel>
                <Select
                  name="rights"
                  defaultValue={selectedUser?.rights}
                  ref={register}
                >
                  {usersRights.map(right => (
                    <option key={right.right} value={right.right}>
                      {right.label}
                    </option>
                  ))}
                </Select>
                <Box mt={2}>
                  <ErrorMessage message={errors.rights?.message} />
                </Box>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                isLoading={isSubmitting}
                colorScheme="blue"
                mr={3}
                type="submit"
              >
                Salvar
              </Button>
              <Button onClick={toggleModal}>Cancelar</Button>
            </ModalFooter>
          </Stack>
        </ModalContent>
      </Modal>
      <RegisterHeader
        handleExportData={handleExportData}
        downloadDisabled={!users.length}
      />
      <Stack flex="1" width="100%">
        <SearchInput
          label="Filtre os Usuários"
          input={{
            placeholder: 'Nome, e-mail, telefone ou empresa',
            value: searchString,
            onChange: setSearchString,
          }}
        />
      </Stack>
      <hr />
      <ReactTable
        getTableBodyProps={getTableProps}
        headerGroups={headerGroups}
        rows={page}
        getTableProps={getTableProps}
        prepareRow={prepareRow}
        rowActions={[
          {
            onClick: (data: any) => {
              handleEdit(data);
            },
            component: (
              <IconButton aria-label="Editar usuário" icon={<Edit />} />
            ),
          },
          {
            onClick: (data: any) => {
              handleClickButtonDelete(data);
            },
            component: (
              <IconButton
                variant="outline"
                colorScheme="red"
                aria-label="Excluir usuário"
                icon={<Trash />}
              />
            ),
          },
        ]}
      />

      <TableFooter
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        previousPage={previousPage}
      />
    </Container>
  );
};

export default Users;
