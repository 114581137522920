import React from 'react';
import { XCircle } from 'react-feather';

import { Container, Item } from './styles';

interface FileListProps {
  files: Array<File>;
  onDelete(index: number): void;
}

const FileList: React.FC<FileListProps> = ({ files, onDelete }) => {
  return (
    <Container>
      {files.map((file, index) => (
        <Item key={file.name}>
          <div>
            <strong>{file.name}</strong>
            <span>{`${file.size} Kb - Não salvo`}</span>
          </div>
          <button type="button" onClick={() => onDelete(index)}>
            <XCircle size={24} />
          </button>
        </Item>
      ))}
    </Container>
  );
};

export default FileList;
